import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import toastReducer from "./toastReducer";
import userRuducer from "./userReducer";
import transactionRuducer from "./transactionsReducer";
import notificationRuducer from "./notificationReducer";
import gasFeesReducer from "./gasFeesReducer";

export default combineReducers({
  loader: loaderReducer,
  toast: toastReducer,
  user: userRuducer,
  transactions: transactionRuducer,
  notifications: notificationRuducer,
  gasFees: gasFeesReducer
});
