import Persona from "persona";
import { useDispatch } from "react-redux";
import { hideLoaderAction } from "../../redux/actions/loaderAction";
import constants from "../../utils/constants";
import Loader from "../loader";

const Inquiry = ({ finishFnxn, setShowKYCVerificationTemplate }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Loader />
      <div className="kyc-verification_modal relative">
        {/* <div className="absolute top-0 right-0">
          <div className="close-btn">x</div>
        </div> */}
        <Persona.Inquiry
          templateId={constants.personaTemplateId}
          environmentId={constants.personaEnvironmentId}
          onLoad={() => {
            console.log("Loaded inline");
          }}
          onReady={() => {
            return dispatch(hideLoaderAction());
          }}
          onComplete={async ({ inquiryId, status, fields }) => {
            // Inquiry completed.
            const data = {
              email: sessionStorage.getItem("email"),
              kycVerificationId: inquiryId,
              kycStatus: status,
              firstName: fields["name-first"].value,
              lastName: fields["name-last"].value,
              middleName: fields["name-middle"].value,
            };
            await finishFnxn(data);
          }}
        />
      </div>
    </>
  );
};

export default Inquiry;
