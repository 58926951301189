import React from "react";
import { Paginator } from "primereact/paginator";

export default function CustomPaginator({
  first,
  setFirst,
  totalRecords,
  rows,
  setRows,
  setCurrentPage,
}) {
  const onPageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
    setCurrentPage(e.page + 1);
  };

  return (
    <Paginator
      first={first}
      rows={rows}
      onPageChange={onPageChange}
      totalRecords={totalRecords}
      rowsPerPageOptions={[10, 20, 30]}
    />
  );
}
