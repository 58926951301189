import React from "react";
import TinLogo from "../assets/images/tinlogo.png";
import BitcoinImg from "../assets/images/bitcoinImgwithphone.png";

const EmailTemplate = () => {
  return (
    <>
      <div className="flex justify-content-center align-items-center p-7 my-4 ">
        <div className="lg:col-6 col-12 emailbg-style p-4 lg:p-8    ">
          <div className=" flex  justify-content-center align-items-center">
            <div className="" style={{ width: "128px", height: "59px" }}>
              <img src={TinLogo} alt="" />
            </div>
          </div>
          <div className="mt-7">
            <h3 className="font-normal text-primary">Dear User</h3>
            <p className="mt-0 text-lg">
              To ensure the security of your account in our app, we've sent you
              a one-time verification code (OTP)
            </p>
          </div>
          <div className="flex flex-column justify-content-center align-items-center">
            <small className="text-sm text-primary pt-4">
              Here is your One-Time Password for verification
            </small>
            <h6 className=" text-5xl my-3">4562</h6>
          </div>
          <div className=" flex justify-content-center">
            <div className="col-11">
              <img src={BitcoinImg} alt="" />
            </div>
          </div>
          <div className="flex flex-column">
            <h3 className="font-normal text-lg">
              Thank you for choosing our service!
            </h3>
            <h3 className="font-normal text-lg mb-0">Warm regards</h3>
            <h4 className="font-normal text-primary mt-0">TINBOX</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailTemplate;
