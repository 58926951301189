export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (sessionStorage.getItem("token")) {
    return JSON.parse(sessionStorage.getItem("token"));
  } else {
    return false;
  }
};

export const authenticate = (token, next) => {
  if (typeof window !== "undefined") {
    sessionStorage.setItem("token", JSON.stringify(token));
    next();
  }
};

export const logout = (next) => {
  if (typeof window !== "undefined") {
    sessionStorage.clear();
    next();
  }
};
