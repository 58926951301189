import React from "react";
import BlurEffect from "../components/BlurEffect";
import Footer from "../layout/Footer";

const TermsAndConditions = () => {
  return (
    <>
      <div className="bgprivacy-img">
        <div className="imgcontent flex justify-content-center align-items-center ">
          <h1 className="text-primary font-semibold text-40 ">
            Terms & Condition
          </h1>
        </div>
      </div>

      <div className="relative  ">
        <BlurEffect right="0%" top="-8%" />
        <BlurEffect left="-3%" top="0%" />
        <div className="container p-3 lg:px-7 lg:py-8">
          <p>
            These Terms of Service ("Terms") govern your use of the services
            provided by Tinbox Transfers Ltd. ("Tinbox Transfers," "we," "us,"
            or "our") as well as your relationship with us. Please read these
            Terms carefully. By using our services, you agree to comply with
            these Terms.
          </p>
          <h3 className="text-primary">1. ACCEPTANCE OF TERMS</h3>
          <p>
            By accessing or using Tinbox Transfers' services, you agree to these
            Terms. If you do not agree to these Terms, please do not use our
            services.
          </p>
          <h3 className="text-primary">
            2. USE RESTRICTIONS AND PROHIBITED CONDUCT
          </h3>
          <p>
            When using Tinbox Transfers' Services, you agree not to do any of
            the following: <br /> <br />
            2.1. Threaten other users with violence;
            <br /> <br /> 2.2. Use hateful, abusive, harassing, libelous, or
            obscene language towards other users;
            <br /> <br /> 2.3. Post any material that infringes or violates any
            third party’s copyright, trademark, trade secret, privacy, or other
            proprietary or property right;
            <br /> <br /> 2.4. Copy any Tinbox Transfers Content onto your own
            or any other website;
            <br /> <br /> 2.5. Post any material that promotes illegal activity,
            could constitute a criminal offense, give rise to civil liability,
            or otherwise violate any applicable law or regulation or these
            Terms; <br /> <br /> 2.6. Purchase or sell any virtual currency with
            knowledge of any material, non-public information relating to such
            virtual currency; <br /> <br /> 2.7. Use the Services to distribute
            viruses or other harmful, disruptive, or destructive files; <br />{" "}
            <br /> 2.8. Use the Services in violation of Company’s or any third
            party's intellectual property or other proprietary or legal rights;{" "}
            <br /> <br /> 2.9. Use or attempt to use another person’s account;{" "}
            <br /> <br /> 2.10. Disrupt or interfere with the security of, or
            otherwise abuse, the Services, or any servers or networks connected
            to the Services; <br /> <br /> 2.11. Attempt to obtain unauthorized
            access to the Services; <br /> <br /> 2.12. Impersonate another
            person; <br /> <br /> 2.13. Use any automated means to purchase or
            sell virtual currencies or otherwise use the Services or trade in
            high volume of virtual currencies, and we reserve the right to
            determine, at our sole discretion whether you are using automated
            means to use the Services or are trading in high volume; <br />
            <br />
            2.14. Systematically harvest or extract data from the Services or
            programmatically register accounts on the Services, including
            through the use of any robot, spider, crawler, or any other
            automated means; <br /> <br /> 2.15. Access the Services for
            purposes of monitoring their availability, performance, or
            functionality, or for any other benchmarking or competitive
            purposes; <br /> <br /> 2.16. Modify or make derivative works based
            upon the Services or any portion thereof; <br /> <br />
            2.17. "Frame" or "mirror" any Tinbox Transfers Content on any other
            server or wireless or internet-based device; <br /> <br /> 2.18.
            Reverse engineer or access the Services in order to (a) build a
            competitive product or service, (b) build a product using similar
            ideas, features, functions, or graphics from the Services, or (c)
            copy any ideas, features, functions, or graphics from the Services;{" "}
            <br /> <br /> 2.19. Copy, decompile, reverse engineer, disassemble,
            attempt to derive the source code of, modify, or create derivative
            works of Services or any part thereof (except as and only to the
            extent any foregoing restriction is prohibited by applicable law);{" "}
            <br /> <br /> 2.20. Use the Services to take advantage of any
            technical glitch, malfunction, failure, delay, default, or security
            breach.
            <br />
            <br />
            In the event that Tinbox Transfers is required to block transactions
            associated with your account in accordance with any government
            sanctions programs, or to comply with any facially legal requests,
            Tinbox Transfers may deactivate your account or halt a pending
            transaction. Tinbox Transfers is not responsible for any losses,
            whether direct or indirect, that you may incur as a result of our
            complying with applicable law, the guidance or direction of any
            regulatory authority or government agency, or any writ of
            attachment, lien, levy, subpoena, warrant, or other legal order.
          </p>
          <h3 className="text-primary">3. YOUR ACCOUNT</h3>
          <p>
            3.1. Certain services provided through the Services may only be
            available to you upon registration for your account. By registering,
            you represent and warrant to Tinbox Transfers that: <br /> <br />{" "}
            3.1.1. You are at least 18 years of age or older and the age of
            majority for using the Services in the jurisdiction where you reside
            as of the time you register with us; <br /> <br /> 3.1.2. All
            information provided by you to us during the registration process is
            truthful, accurate, and complete;
            <br /> <br />
            3.1.3. You have an understanding of virtual currencies and the
            technology that underlies them; <br /> <br /> 3.1.4. You can afford
            to lose all amounts used to purchase stablecoins or virtual
            currencies through the Services; <br /> <br /> 3.1.5. You will
            comply with all terms and conditions of these Terms. <br /> <br />{" "}
            3.2. You acknowledge and agree that we may make any inquiries that
            we consider necessary, either directly or through third parties,
            concerning your identity and creditworthiness, including, without
            limitation, requiring you to take steps to confirm ownership of your
            email address or financial instruments, ordering a credit report, or
            verifying information against third party databases or through other
            sources. <br /> <br /> 3.3. As a registered user, you agree to
            maintain and promptly update your registration data as necessary to
            keep it true, accurate, current, and complete. The Representatives
            will rely on the information you provide. You will be responsible
            for any and all loss, damage, or additional costs that the
            Representatives or others may incur as a result of your submission
            of any false, incorrect, or incomplete information or your failure
            to update your registration or other information that you submit via
            the Services. You acknowledge that you are solely responsible for
            maintaining the confidentiality of your account credentials
            (including any user names, passwords, or security tokens created or
            provided) and that you will be responsible for any loss resulting
            from any unauthorized use of your account. You agree to immediately
            notify us of any unauthorized use of your account. Similarly, you
            are responsible for verifying and maintaining the protection,
            security, and distribution of your account information, including
            account numbers, usernames, and passwords. <br /> <br /> 3.4. From
            time to time, we may also require you to provide further information
            as a condition for continued use of the Services to confirm your
            identity and the purpose of using our Services. Failure to do so
            will likely result in a termination of your account with the
            Company. <br /> <br /> 3.5. All liability relating to password
            management resides with you and under no circumstances, including
            negligence or misconduct, will we be liable for any damages that
            result from the use of your account. <br />
            <br />
            3.6. You can cancel your account at any time by contacting us. We
            reserve the right to maintain your account registration information
            after you close your account for business and regulatory compliance
            purposes. <br />
            <br />
            3.7. You may register only one Account. If you open additional
            accounts, we reserve the right to immediately close such additional
            accounts.
          </p>
          <h3 className="text-primary">4. PURCHASER'S ACKNOWLEDGEMENTS</h3>
          <p>
            By purchasing via the Services, you agree and acknowledge that:
            <br /> <br /> 4.1. The virtual currencies available for purchase may
            be used only for the purpose of trading on the Services. The virtual
            currencies are not an investment and should not be considered as
            such. They are not backed or insured by any government or central
            bank. <br /> <br /> 4.2. You will not transfer, directly or
            indirectly, any virtual currency or other assets acquired via the
            Services to another country or use these virtual currencies or other
            assets for any purpose prohibited by the applicable laws of Canada
            or your own domicile. <br /> <br /> 4.3. Your purchase of virtual
            currencies via the Services involves a high degree of risk. By
            purchasing virtual currencies, you acknowledge and agree that you
            may lose your entire deposit. You are advised to consider carefully
            whether the potential reward of purchasing virtual currencies
            outweighs the risks involved. <br /> <br /> 4.4. Virtual currencies
            and their value are highly volatile, and their value can fluctuate
            significantly within a very short period. You are responsible for
            understanding the risks associated with virtual currencies and
            should consider your risk tolerance and financial situation before
            purchasing them. <br /> <br /> 4.5. Your purchases of virtual
            currencies may not be immediately available for withdrawal, and we
            may hold your virtual currencies for a specified period as part of
            our security and compliance protocols. <br /> <br /> 4.6. Tinbox
            Transfers is not a financial advisor, and we do not provide
            investment advice or recommendations. Any information, analysis, or
            commentary provided by Tinbox Transfers is for informational
            purposes only and should not be considered investment advice. <br />{" "}
            <br /> 4.7. Your funds may be subject to loss or theft. We implement
            security measures to protect your assets, but Tinbox Transfers
            cannot guarantee the complete security of your virtual currencies.
            You acknowledge that Tinbox Transfers is not responsible for any
            loss, theft, or damage to your virtual currencies. <br /> <br />{" "}
            4.8. You will be responsible for any tax implications arising from
            your purchases of virtual currencies, and you will report such
            transactions as required by applicable tax laws.
          </p>
          <h3 className="text-primary">5. FEES</h3>
          <p>
            5.1. Use of our Services may be subject to fees, which are disclosed
            on our website. You agree to pay all applicable fees for the use of
            our Services. Tinbox Transfers reserves the right to change its fee
            structure and will notify users of any changes through our website
            or via email.
          </p>
          <h3 className="text-primary">6. PRIVACY AND DATA PROCESSING:</h3>
          <p>
            6.1. Tinbox Transfers is committed to protecting your privacy and
            processes personal data in accordance with our Privacy Policy, which
            is an integral part of these Terms. By using our services, you agree
            to the terms outlined in our Privacy Policy.
          </p>
          <h3 className="text-primary">7. INTELLECTUAL PROPERTY</h3>
          <p>
            7.1. All content, logos, trademarks, and other intellectual property
            rights related to Tinbox Transfers are owned by or licensed to
            Tinbox Transfers and protected by applicable copyright and trademark
            laws. You may not use, copy, reproduce, republish, upload, post,
            transmit, distribute, or modify the Tinbox Transfers content or
            materials without our prior written consent.
          </p>
          <h3 className="text-primary">8. LIMITATION OF LIABILITY</h3>
          <p>
            8.1. Tinbox Transfers and its affiliates shall not be liable for any
            direct, indirect, punitive, incidental, special, or consequential
            damages, or any damages whatsoever, including, without limitation,
            damages for loss of use, data, or profits, arising out of or in any
            way connected with the use or performance of the Services.
          </p>
          <p>
            8.2. Tinbox Transfers' liability is limited to the extent permitted
            by law, regardless of whether any remedy provided fails in its
            essential purpose.
          </p>
          <h3 className="text-primary">
            9. GOVERNING LAW AND DISPUTE RESOLUTION:
          </h3>
          <p>
            9.1. These Terms and any disputes arising from or relating to the
            interpretation, validity, or enforcement of these Terms will be
            governed by and construed in accordance with the laws of Alberta,
            Canada. Disputes that cannot be resolved through arbitration or
            mediation will be subject to the exclusive jurisdiction of the
            courts in Alberta, Canada.
          </p>
          <h3 className="text-primary">10. CONTACT INFORMATION</h3>
          <p>
            If you have any questions, concerns, or would like to contact us
            regarding these Terms or our Services, please reach out to us at:
          </p>
          <h3 className="text-primary my-0">Tinbox Transfers Ltd.</h3>
          <p className="m-0 py-0">
            <a href="mailto:legal@tinbox.app " className="text-white underline">
              legal@tinbox.app
            </a>
          </p>
        </div>
      </div>

      <div className="container-fluid bg-secondary">
        <Footer />
      </div>
    </>
  );
};

export default TermsAndConditions;
