import { validEmail } from "./regex/regex";

export const formValidator = (formKeyLabels, form, errors) => {

    const formKeys = Object.keys(formKeyLabels);

    let formErrors = { ...errors };

    for (let key of formKeys) {
        if (!form[key]) {
            formErrors = {
                ...formErrors,
                [key]: `Please enter ${formKeyLabels[key]}`,
            };
        } else {
            delete formErrors[key];
        }
    }
    return formErrors;
};

export const fieldValidator = (name, value, errors, label) => {
    let formErrors = { ...errors };

    if (!value) {
        formErrors = {
            ...formErrors,
            [name]: `Please enter ${label}`,
        };
    } else {
        delete formErrors[name];
    }
    return formErrors;
}

export const passwordValidator = async (data, passwordValidations, confirmPasswordValidations) => {

    let stringValidatorValues;
    let errors = {};

    if (data.email) {
        if (data.email === "" || !data.email.length) {
            errors.email = "Enter your Email";
            return errors;
        } else if (!validEmail.test(data.email)) {
            errors.email = "Enter your valid Email";
            return errors;
        }
        if (!data.password) {
            errors.password = "Enter your Password";
            return errors;
        }
    }

    stringValidatorValues = Object.values(passwordValidations);

    if (!stringValidatorValues.every((element) => element === true)) {
        errors.password = "Password must match all requirements";
        return errors;
    }

    if (!data.confirmPassword) {
        errors.confirmPassword = "Enter Confirm Password";
        return errors;
    }

    stringValidatorValues = Object.values(confirmPasswordValidations);

    if (!stringValidatorValues.every((element) => element === true)) {
        errors.confirmPassword = "Confirm Password must match all requirements";
        return errors;
    }

    if (data.password !== data.confirmPassword) {
        errors.confirmPassword = "Password mismatch";
        return errors;
    }

};