import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import icons from "../../utils/icons";
import { CustomInput } from "../../components/InputFields/CustomInput";
import constants from "../../utils/constants";
import api from "../../services/api";
import { showToast } from "../../redux/actions/toastAction";
import { useDispatch } from "react-redux";

const VerifyCode = ({ showEmail, ForgotPasswordAPI }) => {
  const [showOtp, setShowOtp] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Logo } = icons();
  const [isError, setIsError] = useState({});
  // const [showCreateNPPage, setshowCreateNPPage] = useState();
  const [counter, setCounter] = useState(60);
  const [data, setData] = useState({
    email: showEmail,
    otp: "",
    otpType: 2,
  });

  const handleChange = (name) => (event) => {
    setData({ ...data, [name]: event.target.value });
  };

  let errors = {
    otp: false,
  };

  const Validations = async () => {
    if (!data.otp) {
      errors.otp = "Enter your OTP";
    }
    setIsError(errors);
    return errors;
  };

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);
  const resendOtp = () => {
    setCounter(60);
  };

  const Otp = async () => {
    const res = await api("post", constants.endPoints.Otp, data);
    if (res.success) {
      setShowOtp(res.data);

      navigate("/create-password", {
        state: { email: showEmail, otp: data.otp },
      });
      dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

  const handleSubmit = async () => {
    let errors = await Validations();
    if (!errors.otp) Otp();
  };

  return (
    <>
      <div className=" ">
        <div className="grid p-0 m-0">
          <div className="md:col-6 bgsign-image">
            <div className="flex flex-column align-items-center px-4 lg:px-0 w-12 lg:w-9 ">
              <div className="">
                <h3 className="lg:text-6xl text-30 text-primary font-medium lg:line-height-3 m-0">
                  Exchange the Crypto in Mintutes
                </h3>
                <p className="text-sm md:text-base lg:py-2 w-12 lg:w-9">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </p>
              </div>
            </div>
          </div>
          <div className="lg:col-5 col-12 p-0 lg:mt-8 mb-8 m-auto flex flex-column justify-content-center  ">
            <div className="surface-500 border-round-2xl lg:px-6 px-4 py-6">
              <div className="text-center pb-3">
                <small style={{ width: "60px", height: "27px" }}>
                  <Logo />
                </small>
              </div>
              <h3 className="lg:text-3xl text-26 font-medium text-primary my-2">
                Verification Code
              </h3>
              <small>
                Enter the 6-digit verification code sent to <br /> {showEmail}
              </small>
              <div className="my-3">
                <CustomInput
                  placeholder="Enter your 6 digit code"
                  value={data.otp}
                  onChange={handleChange("otp")}
                  maxLength={"6"}
                ></CustomInput>
                {isError?.otp && (
                  <p className="text-red-600 text-xs mt-1">{isError?.otp}</p>
                )}
              </div>
              {counter === 0 ? (
                <p
                  className="text-primary cursor-pointer"
                  onClick={() => {
                    ForgotPasswordAPI();
                    resendOtp(true);
                  }}
                >
                  Resend code
                </p>
              ) : (
                <p className="text-gray-800">
                  Resend code &nbsp;
                  <span className="text-white">Time left : {counter} sec</span>
                </p>
              )}
              <div className="col-12 mx-0 px-0">
                <ButtonComponent
                  onClick={handleSubmit}
                  label="Submit"
                  className="col-12"
                ></ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyCode;
