import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import CustomPaginator from "../CustomPaginator";

const TableData = ({
  data,
  columns,
  selectionMode,
  delRow,
  selected,
  changeSelection,
  key,
  checked,
  rows,
  setRows,
  first,
  setFirst,
  count,
  setCurrentPage,
  header,
  globalFilterFields,
  filters,
  filterField,
  filterElement,
  emptyMessage,
  pagination,
  totalRecords,
  onApplyFilters,
}) => {
  const onPageChangeEvent = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setCurrentPage(event.page + 1);
  };

  const dynamicColumns = columns.map((col, i) => {
    return selectionMode && i === 0 ? (
      <Column
        filterField={filterField}
        selectionMode="multiple"
        headerStyle={{ width: "3rem" }}
        style={{ textAlign: "center" }}
      ></Column>
    ) : (
      <Column
        filterElement={col.filterElement ? col.filterElement : null}
        filter={col.filter ? true : false}
        key={col.field}
        field={col.field}
        sortable={col.sorting ? true : false}
        header={col.header}
        body={col.body ? col.body : null}
        showFilterOperator={
          col.showFilterOperator ? col.showFilterOperator : false
        }
        filterMatchModeOptions={
          col.filterMatchModeOptions ? col.filterMatchModeOptions : null
        }
        showAddButton={col.showAddButton ? col.showAddButton : false}
        showFilterMatchModes={
          col.showFilterMatchModes ? col.showFilterMatchModes : false
        }
      />
    );
  });

  return (
    <div>
      <div className="">
        <DataTable
          showGridlines={false}
          value={data}
          stripedRows
          filterElement={filterElement}
          delRow={delRow}
          dataKey={key}
          checked={checked}
          filters={filters}
          globalFilterFields={globalFilterFields}
          header={header}
          emptyMessage={emptyMessage}
          onFilter={onApplyFilters}
        >
          {dynamicColumns}
        </DataTable>
        {!pagination ? (
          <CustomPaginator
            first={first}
            setFirst={setFirst}
            rows={rows}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
            setRows={setRows}
          ></CustomPaginator>
        ) : null}
      </div>
    </div>
  );
};
export default TableData;
