import React from "react";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";

export function PasswordInputWithStrength({
  placeholder,
  onChange,
  label,
  value,
  required,
  passwordValidations,
  checkPasswordValidations,
}) {
  const footer = (
    <>
      <Divider />
      <div>
        <div>
          <span className="flex align-items-center">
            {passwordValidations.atLeast8Characters ? (
              <i className="pi pi-check text-xs text-green-500"></i>
            ) : (
              <i className="pi pi-times text-xs text-red-500"></i>
            )}
            <small className="mx-2">At least 8 character</small>
          </span>
        </div>
        <div>
          <span className="flex align-items-center my-1">
            {passwordValidations.atLeastOneLowerCase ? (
              <i className="pi pi-check text-xs text-green-500"></i>
            ) : (
              <i className="pi pi-times text-xs text-red-500"></i>
            )}
            <small className="mx-2">At least one small letter</small>
          </span>
        </div>
        <div>
          <span className="flex align-items-center">
            {passwordValidations.atLeastOneUpperCase ? (
              <i className="pi pi-check text-xs text-green-500"></i>
            ) : (
              <i className="pi pi-times text-xs text-red-500"></i>
            )}
            <small className="mx-2">At least one capital letter</small>
          </span>
        </div>
        <div>
          <span className="flex align-items-center my-1">
            {passwordValidations.atLeastOneNumber ? (
              <i className="pi pi-check text-xs text-green-500"></i>
            ) : (
              <i className="pi pi-times text-xs text-red-500"></i>
            )}
            <small className="mx-2">At least one number</small>
          </span>
        </div>
        <div>
          <span className="flex align-items-center my-1">
            {passwordValidations.atLeastOneSpecialCharacter ? (
              <i className="pi pi-check text-xs text-green-500"></i>
            ) : (
              <i className="pi pi-times text-xs text-red-500"></i>
            )}
            <small className="mx-2">At least one special character</small>
          </span>
        </div>
      </div>
    </>
  );

  return (
    <div className="p-1">
      <label htmlFor="" className="">
        {label}
      </label>
      {required ? <span className="text-red-400">*</span> : ""}
      <div className="p-inputgroup flex-1 ">
        <Password
          //   value={value}
          placeholder={placeholder}
          className="border-none"
          onChange={(e) => {
            checkPasswordValidations(e.target.value);
            onChange(e);
          }}
          toggleMask
          footer={footer}
          strongRegex={"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})"}
        />
      </div>
    </div>
  );
}
