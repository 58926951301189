import React, { useEffect, useState } from "react";
import { CustomInput } from "../../components/InputFields/CustomInput";
import MyProfileIMG from "../../assets/images/profile-img.png";
import verifyIMG from "../../assets/images/verify-green.png";
import EditIMG from "../../assets/images/edit.png";
import { useNavigate } from "react-router-dom";
import { PasswordInput } from "../../components/InputFields/PasswordInput";
import ButtonComponent from "../../components/Buttons/button";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  getUserInfo,
  updatePassword,
} from "../../redux/actions/userActions";
import { PasswordInputWithStrength } from "../../components/InputFields/PasswordInputWithStrength";
import {
  atLeastOneSpecialCharacter,
  atLeastOneUpperCase,
  atLeastOneLowerCase,
  atLeastOneNumber,
  atLeastOneCapitalCase,
} from "../../utils/regex/regex";
import { passwordValidator } from "../../utils/validations";
import constants from "../../utils/constants";
import Avatar from "react-avatar";

const MyProfile = () => {
  const dispatch = useDispatch();
  const [showResetScreen, setShowResetScreen] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { userDetails } = useSelector((state) => state.user);

  const [passwordData, setPasswordData] = useState({
    email: userDetails.email,
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordValidations, setPasswordValidations] = useState({
    atLeast8Characters: false,
    atLeastOneLowerCase: false,
    atLeastOneUpperCase: false,
    atLeastOneNumber: false,
    atLeastOneSpecialCharacter: false,
  });

  const checkPasswordValidations = (value) => {
    return setPasswordValidations({
      atLeast8Characters: value.length >= 8,
      atLeastOneLowerCase: atLeastOneLowerCase.test(value),
      atLeastOneUpperCase: atLeastOneCapitalCase.test(value),
      atLeastOneNumber: atLeastOneNumber.test(value),
      atLeastOneSpecialCharacter: atLeastOneSpecialCharacter.test(value),
    });
  };

  const [confirmPasswordValidations, setConfirmPasswordValidations] = useState({
    atLeast8Characters: false,
    atLeastOneLowerCase: false,
    atLeastOneUpperCase: false,
    atLeastOneNumber: false,
    atLeastOneSpecialCharacter: false,
  });

  const checkConfirmPasswordValidations = (value) => {
    return setConfirmPasswordValidations({
      atLeast8Characters: value.length >= 8,
      atLeastOneLowerCase: atLeastOneLowerCase.test(value),
      atLeastOneUpperCase: atLeastOneCapitalCase.test(value),
      atLeastOneNumber: atLeastOneNumber.test(value),
      atLeastOneSpecialCharacter: atLeastOneSpecialCharacter.test(value),
    });
  };

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  const [isError, setIsError] = useState({});

  const handleChange = (name) => (event) => {
    const stringValidatorValues = Object.values(passwordValidations);

    if (!stringValidatorValues.every((element) => element === true)) {
      errors.password = "Password must match all requirements";
    } else {
    }
    setPasswordData({ ...passwordData, [name]: event.target.value });
  };

  let errors = {
    password: false,
    confirmPassword: false,
  };

  const handleSubmit = async () => {
    let formErrors = await passwordValidator(
      passwordData,
      passwordValidations,
      confirmPasswordValidations
    );
    setIsError({ ...formErrors });
    if (!formErrors) {
      const payload = {
        ...passwordData,
        email: passwordData.email,
      };

      dispatch(updatePassword(payload, setShowResetScreen));
    }
  };

  return (
    <>
      {!showResetScreen ? (
        <div
          className="container flex align-items-center justify-content-center py-6   "
          style={{ minHeight: "90vh" }}
        >
          <div
            className="col-12 lg:col-6 flex flex-column justify-content-center p-3 border-round-lg "
            style={{ background: "#1B1D25" }}
          >
            {/* <div
              className="flex justify-content-end cursor-pointer"
              onClick={() => navigate("/edit-profile")}
            >
              <img src={EditIMG} alt="" style={{ width: "24px" }} />
            </div> */}
            <div className="relative flex justify-content-center pt-5">
              {userDetails.firstName ? (
                <Avatar
                  name={`${userDetails.firstName} ${userDetails.lastName}`}
                  round
                />
              ) : (
                <img
                  src={MyProfileIMG}
                  alt=""
                  style={{
                    width: "149px",
                    height: "149px",
                    borderRadius: "50%",
                  }}
                />
              )}
              <div className="absolute bottom-0" style={{ marginLeft: "75px" }}>
                <img
                  src={verifyIMG}
                  alt=""
                  style={{ width: "25px", height: "25px" }}
                />
              </div>
            </div>
            <div className="flex flex-wrap mt-3">
              <CustomInput
                label={"First name"}
                disabled
                value={userDetails.firstName}
              ></CustomInput>
              <CustomInput
                label={"Last name"}
                disabled
                value={userDetails.lastName}
              ></CustomInput>
            </div>
            <div className="">
              <CustomInput
                label="Email"
                disabled
                value={userDetails.email}
              ></CustomInput>
              {!userDetails.isSocialLogin ? (
                <p
                  className="text-red-700 font-bold mx-2 cursor-pointer pb-4"
                  onClick={() => setShowResetScreen(true)}
                >
                  Reset Password
                </p>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div
          className=" container flex align-items-center justify-content-center py-6 "
          style={{ minHeight: "90vh" }}
        >
          <div
            className="col-12 lg:col-5 flex flex-column justify-content-center p-3  border-round-lg mt-5 py-6"
            style={{ background: "#1B1D25" }}
          >
            <div className="flex justify-content-center font-bold text-26">
              Reset Password
            </div>

            <div className="col-12  mt-3 ">
              <PasswordInput
                label={"Old Password"}
                value={passwordData.password}
                onChange={handleChange("oldPassword")}
                className="col-12"
              />
              <PasswordInputWithStrength
                type="password"
                onChange={handleChange("password")}
                placeholder="Password"
                value={passwordData.password}
                required
                icon="pi-eye"
                label="Password"
                passwordValidations={passwordValidations}
                checkPasswordValidations={checkPasswordValidations}
              ></PasswordInputWithStrength>
              {isError?.password && (
                <p className="text-red-600 text-xs mt-1">{isError?.password}</p>
              )}
              <PasswordInputWithStrength
                type="password"
                onChange={handleChange("confirmPassword")}
                placeholder="Enter Confirm Password"
                value={passwordData.confirmPassword}
                required
                icon="pi-eye"
                label="Confirm Password"
                passwordValidations={confirmPasswordValidations}
                checkPasswordValidations={checkConfirmPasswordValidations}
              ></PasswordInputWithStrength>
              {isError?.confirmPassword && (
                <p className="text-red-600 text-xs mt-1">
                  {isError?.confirmPassword}
                </p>
              )}
            </div>
            <div className="flex flex-wrap ">
              <div className="lg:col-6 col-12 ">
                <ButtonComponent
                  onClick={() => setShowResetScreen(false)}
                  label={"Cancel"}
                  className={"col-12 bg-transparent text-primary border-1"}
                ></ButtonComponent>
              </div>
              <div className="lg:col-6 col-12">
                <ButtonComponent
                  label={"Reset Password"}
                  onClick={handleSubmit}
                  className={"col-12"}
                ></ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyProfile;
