import { ReactComponent as Logo } from "../assets/svg/tinbox_logo.svg";
import { ReactComponent as RegisterIcon } from "../assets/svg/register.svg";
import { ReactComponent as VerifyKYCIcon } from "../assets/svg/VerifyKYc.svg";
import { ReactComponent as ExchangeIcon } from "../assets/svg/exchange.svg";
import { ReactComponent as PolygonIcon } from "../assets/svg/polygon.svg";
import { ReactComponent as EthereumIcon } from "../assets/svg/Ethereum.svg";
import { ReactComponent as TronIcon } from "../assets/svg/Tron.svg";
import { ReactComponent as ResetCompleteImg } from "../assets/svg/resetcomplete.svg";
import { ReactComponent as EyeImg } from "../assets/svg/eye.svg";
import { ReactComponent as EyeSlashImg } from "../assets/svg/eye-slash.svg";
import { ReactComponent as GoogleIcon } from "../assets/svg/google-icon.svg";
import { ReactComponent as KycverifyIcon } from "../assets/svg/KycverifyIcon.svg";
import { ReactComponent as FillFormIcon } from "../assets/svg/fill-form.svg";
import { ReactComponent as IdentityDocumentIcon } from "../assets/svg/identity-Document.svg";
import { ReactComponent as SelfieIcon } from "../assets/svg/selfie.svg";
import { ReactComponent as TickIcon } from "../assets/svg/circle-tick.svg";
import { ReactComponent as CrossIcon } from "../assets/svg/circle-cross.svg";
import { ReactComponent as VerifyUser } from "../assets/svg/verify-user.svg";
import { ReactComponent as Notif } from "../assets/svg/notification.svg";
import { ReactComponent as LogoutIcon } from "../assets/svg/logout.svg";
import { ReactComponent as EmailBitcoinImg } from "../assets/svg/EmailBitcionImg.svg";

const icons = () => {
  return {
    EmailBitcoinImg,
    LogoutIcon,
    VerifyKYCIcon,
    Notif,
    VerifyUser,
    TickIcon,
    CrossIcon,
    FillFormIcon,
    IdentityDocumentIcon,
    SelfieIcon,
    KycverifyIcon,
    GoogleIcon,
    EyeSlashImg,
    EyeImg,
    Logo,
    RegisterIcon,
    ExchangeIcon,
    PolygonIcon,
    EthereumIcon,
    TronIcon,
    ResetCompleteImg,
  };
};

export default icons;
