import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import React from "react";
import { InputNumber } from "primereact/inputnumber";

export function CustomInput({
  placeholder,
  id,
  name,
  icon,
  value,
  type,
  maxLength,
  onChange,
  extraClass,
  mask,
  label,
  required,
  disabled,
  style,
  autoFocus,
  useGrouping = true,
}) {
  return (
    <div className={`w-full flex-1 input-outline my-2 ${extraClass}`}>
      <div className="flex flex-column p-1 ">
        <div className="flex  font-normal">
          <label htmlFor="">{label}</label>
          {required ? <span className="text-red-500">*</span> : ""}
        </div>
        {/* <span className="p-inputgroup-addon show-arrow text-white border-none">
        {icon}
      </span> */}
        {mask ? (
          <InputMask
            type={type ? type : "text"}
            className="input-bg border-none"
            name={name}
            mask={mask}
            value={value}
            placeholder={placeholder}
            onChange={(e) =>
              onChange &&
              onChange({
                ...e,
                name: e.name,
                value: e.value,
                label: label,
              })
            }
            id={id}
          ></InputMask>
        ) : type === "number" ? (
          <InputNumber
            className="border-none number-field-border"
            name={name}
            value={value}
            useGrouping={useGrouping}
            height={57}
            disabled={disabled}
            placeholder={placeholder}
            style={{ ...style, height: "57px" }}
            onChange={(e) =>
              onChange &&
              onChange({
                ...e,
                name: name,
                value: e.value,
                label: label,
              })
            }
            id={id}
            maxLength={maxLength}
          />
        ) : (
          <InputText
            type={type ? type : "text"}
            className="input-bg border-none border-round-lg"
            name={name}
            value={value}
            autoFocus={autoFocus}
            height={57}
            disabled={disabled}
            placeholder={placeholder}
            style={{ height: "57px" }}
            onChange={(e) =>
              onChange &&
              onChange({
                ...e,
                name: e.target.name,
                value: e.target.value,
                label: label,
              })
            }
            id={id}
            maxLength={maxLength}
          />
        )}
      </div>
    </div>
  );
}
