import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../utils/constants";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

const getNotifications = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.endPoints.getNotifications);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.NOTIFICATIONS,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};

const markAsRead = (id, data) => async (dispatch) => {

    const res = await api("put", Constants.endPoints.markNotificationAsRead + id);
    if (res.success) {
        dispatch(getNotifications());
        // dispatch(showToast({ severity: "success", summary: res.message }));
    }
};

export { getNotifications, markAsRead };
