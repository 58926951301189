import React from "react";
import icons from "../utils/icons";
import { NavLink, useNavigate } from "react-router-dom";
import NavLinks from "./NavLinks";

export default function Footer() {
  const navigate = useNavigate();
  const { footerMenu } = NavLinks();
  const { Logo } = icons();
  const GoTOP = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="container pt-5 text-white">
      <div className="grid">
        <div className="col-12  lg:col-4">
          <NavLink to="/">
            <Logo />
          </NavLink>
          <p className="font-medium">
            Your trusted partner to on-ramp and <br /> off-ramp Cryto Currency.
          </p>
        </div>
        <div className="col-12 md:col" />
        {footerMenu.map((section, index) => {
          return (
            <div className="col footer-nav-sections" key={index}>
              <p className="font-semibold text-xl text-primary">
                {section.title}
              </p>
              {section.menu.map((menu, i) => {
                return (
                  <p key={i}>
                    <NavLink className="no-underline text-white" to={menu.link}>
                      <span onClick={GoTOP}>{menu.title}</span>
                    </NavLink>
                  </p>
                );
              })}
            </div>
          );
        })}

        <div className="col-12 md:col-6 lg:col-3">
          <p className="font-semibold text-primary text-xl">Contact Us</p>
          <p>
            <div className="flex ">
              <i className="pi pi-map-marker mr-3 mt-2" />
              <span>1800-3305 AVE SW TOWER 1 CALGARY, AB, CANADA T2P0L4</span>
            </div>
          </p>
          <p>
            <i className="pi pi-envelope mr-3" />
            Support@tinbox.app
          </p>
          {/* <p>
            <i className="pi pi-phone mr-3" />
            21343441123
          </p> */}
        </div>
      </div>
      <hr className="opacity-10 hidden lg:block" />
      <div className=" hidden lg:block">
        <div className="grid mt-3  ">
          <div className="col-12 md:col-8 text-center md:text-left  ">
            ©2023 tinbox. All rights reserved
          </div>
          <div className="col-6 md:col-2 text-right">
            <NavLink to="/privacy-policy" className="text-white">
              <span onClick={GoTOP}>Privacy Policy</span>
            </NavLink>
          </div>
          <div className="col-6 md:col-2 text-left md:text-right">
            <NavLink to="/terms&Conditions" className="text-white">
              <span onClick={GoTOP}>Terms & Condition</span>
            </NavLink>
          </div>
        </div>
      </div>
      <p className="m-0  pb-4 hidden lg:block"></p>
    </div>
  );
}
