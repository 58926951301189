import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../utils/constants";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import constants from "../../utils/constants";

const getUser = (returnData) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.endPoints.users);
  if (res.success) {
    dispatch(hideLoaderAction());
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  }
  dispatch(hideLoaderAction());
};

const getUserInfo = () => async (dispatch) => {
  dispatch(showLoaderAction());

  const res = await api("get", Constants.endPoints.users);
  if (res.success) {
    dispatch(hideLoaderAction());
    if (res.data) {
      dispatch({
        type: types.USER_DETAILS,
        payload: res.data,
      });
    }
  }
  dispatch(hideLoaderAction());
};

const checkPasswordLock = (email, setPasswordTryError, setPasswordLockTime, setTimer) => async (dispatch) => {
  const res = await api("post", Constants.endPoints.checkPasswordLock, { email });
  if (res.success) {
    if (res.data) {
      let str = !res.data.passwordLockTime && res.data.passwordLockCount > 0 && res.data.passwordLockCount < 5
        ? `You have ${5 - res.data.passwordLockCount
        } more attempts remaining to enter your password correctly. After this, your account may be temporarily locked for security reasons,`

        : 1800 - Math.round((Date.now() - res.data.passwordLockTime) / 1000) > 0 ? `Your account has been temporarily locked due to multiple unsuccessful login attempts. Time remaining:` : "";

      setPasswordTryError(str);
      if (res.data.passwordLockTime) {
        setPasswordLockTime(1800 - Math.round((Date.now() - res.data.passwordLockTime) / 1000));
      }
    }
  } else {
    setPasswordTryError();
    setPasswordLockTime();
  }
};

const editUser = (data, navigate) => async (dispatch) => {
  dispatch(showLoaderAction());

  const payload = {
    ...data,
  };

  const res = await api("put", Constants.endPoints.updateUser, payload);
  if (res.success) {
    dispatch(getUserInfo());
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
    return navigate ? navigate(-1) : null;
  } else {
    dispatch(hideLoaderAction());
  }
};

const updatePassword = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());

  const payload = {
    ...data,
  };

  const res = await api("post", Constants.endPoints.ResetPassword, payload);
  if (res.success) {
    getUser(null);
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
    next();
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
    dispatch(hideLoaderAction());
  }
};

const getAdminDetails = () => async (dispatch) => {
  const res = await api("post", `${Constants.endPoints.adminDetails}`, { email: constants.adminEmail });
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ADMIN_DETAILS,
        payload: res.data,
      });
    }
  } else {
    dispatch({
      type: types.ADMIN_DETAILS,
      payload: {}
    });
  }
};

export { getUser, editUser, updatePassword, getUserInfo, checkPasswordLock, getAdminDetails };
