import React from "react";
import BlurEffect from "../../components/BlurEffect";
import { CustomInput } from "../../components/InputFields/CustomInput";
import CustomTextArea from "../../components/InputFields/CustomTextArea";
import ButtonComponent from "../../components/Buttons/button";
import Footer from "../../layout/Footer";
import { useState } from "react";
import constants from "../../utils/constants";
import api from "../../services/api";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/actions/toastAction";
import {
  hideLoaderAction,
  showLoaderAction,
} from "../../redux/actions/loaderAction";
import Loader from "../../components/loader";
import { fieldValidator, formValidator } from "../../utils/validations";
import { validEmail } from "../../utils/regex/regex";

const Index = () => {
  const dispatch = useDispatch();

  const [contactUsForm, setContactUsForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  let [errors, setErrors] = useState({});

  const handleChange = (name, label) => async (event) => {
    const value = name === "phoneNumber" ? event.value : event.target.value;
    const fieldValidation = fieldValidator(name, value, errors, label);
    setErrors({ ...fieldValidation });

    if (name === "phoneNumber") {
      setContactUsForm({ ...contactUsForm, [name]: event.value });
    } else {
      setContactUsForm({ ...contactUsForm, [name]: event.target.value });
    }
  };

  const formValidationKeyLabels = {
    firstName: "first name",
    lastName: "last name",
    email: "email",
    phoneNumber: "phone number",
    message: "message",
  };

  const contactUs = async () => {
    try {
      const formErrors = await formValidator(
        formValidationKeyLabels,
        contactUsForm,
        errors
      );

      if (!validEmail.test(contactUsForm.email)) {
        formErrors.email = "Enter your valid Email";
      }

      setErrors({ ...formErrors, ...errors });

      if (Object.values(formErrors).length) return;

      dispatch(showLoaderAction());
      const res = await api(
        "post",
        constants.endPoints.contactUs,
        contactUsForm
      );

      if (res.success) {
        dispatch(hideLoaderAction());
        setContactUsForm({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
        dispatch(showToast({ severity: "success", summary: res.message }));
      }
    } catch (error) {
      dispatch(hideLoaderAction());
      // Handle error here
      console.error("Error uploading images:", error);
      throw error;
    }
  };

  return (
    <>
      <Loader />
      <div className="relative lg:py-8 md:py-8 pb-6 ">
        <BlurEffect right="0%" top="-8%" />
        <BlurEffect left="-3%" top="0%" />
        <h1 className="text-primary font-semibold text-40 text-center">
          Contact Us
        </h1>
        <div className="relative container contact-detail p-3 lg:px-7 lg:pb-8">
          <div className="lg:px-6">
            <div className=" lg:flex gap-4 mt-5">
              <div className="col">
                <CustomInput
                  placeholder="Enter First Name"
                  label="First Name"
                  name="firstName"
                  onChange={handleChange("firstName", "First name")}
                  value={contactUsForm.firstName}
                  className="col"
                ></CustomInput>
                {errors?.firstName && (
                  <p className="text-red-600 text-xs mt-1">
                    {errors?.firstName}
                  </p>
                )}
              </div>
              <div className="col">
                <CustomInput
                  placeholder="Enter Last Name"
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange("lastName", "Last name")}
                  value={contactUsForm.lastName}
                  className="col"
                ></CustomInput>
                {errors?.lastName && (
                  <p className="text-red-600 text-xs mt-1">
                    {errors?.lastName}
                  </p>
                )}
              </div>
            </div>
            <div className="lg:flex gap-4 lg:my-3 my-1">
              <div className="col">
                <CustomInput
                  placeholder="Enter your email"
                  label="Email"
                  name="email"
                  value={contactUsForm.email}
                  onChange={handleChange("email", "Email")}
                  className="col"
                ></CustomInput>
                {errors?.email && (
                  <p className="text-red-600 text-xs mt-1">{errors?.email}</p>
                )}
              </div>
              <div className="col">
                <CustomInput
                  placeholder="Enter Phone Number"
                  type={"number"}
                  label="Phone Number"
                  useGrouping={false}
                  name="phoneNumber"
                  value={contactUsForm.phoneNumber}
                  onChange={handleChange("phoneNumber", "Phone number")}
                  className="col"
                ></CustomInput>
                {errors?.phoneNumber && (
                  <p className="text-red-600 text-xs mt-1">
                    {errors?.phoneNumber}
                  </p>
                )}
              </div>
            </div>
            <div className="col-12">
              <CustomTextArea
                label="Message"
                rows={4}
                name="message"
                value={contactUsForm.message}
                onChange={handleChange("message", "Message")}
              ></CustomTextArea>
              {errors?.message && (
                <p className="text-red-600 text-xs mt-1">{errors?.message}</p>
              )}
            </div>
            <div className="px-3 flex lg:justify-content-start  justify-content-center mb-3">
              <ButtonComponent
                label="Submit"
                className="my-3 px-6"
                onClick={contactUs}
              ></ButtonComponent>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-secondary">
        <Footer />
      </div>
    </>
  );
};

export default Index;
