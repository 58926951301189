import React from "react";

const NavLinks = () => {
  const footerMenu = [
    {
      title: "About",
      menu: [
        {
          title: "About Us",
          link: "/about-us",
        },
        {
          title: "Support",
          link: "/contact-us",
        },
      ],
    },
    // {
    //   title: "Socials",
    //   menu: [
    //     {
    //       title: "Instagram",
    //       link: "",
    //     },
    //     {
    //       title: "Twitter",
    //       link: "",
    //     },
    //     {
    //       title: "Facebook",
    //       link: "",
    //     },
    //   ],
    // },
  ];

  return { footerMenu };
};

export default NavLinks;
