import React from "react";

export default function BlurEffect({
  left = "",
  right = "",
  top = "",
  bottom = "",
}) {
  return (
    <div
      className="absolute blue-effect"
      style={{ left: left, right: right, top: top, bottom: bottom }}
    />
  );
}
