import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../utils/constants";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

const getTransactions = (setLoading, page, limit, searchKeys, search, filterData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.endPoints.getuserTransactions}?page=${page}&limit=${limit}&searchKeys=${JSON.stringify(searchKeys)}
    &searchString=${search}&filterData=${JSON.stringify(filterData)}`);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            dispatch({
                type: types.TRANSACTIONS,
                payload: res.data,
                count: res.count
            });
        } else {
            dispatch({
                type: types.TRANSACTIONS,
                payload: [],
                count: 0
            });
        }
    } else {
        dispatch(hideLoaderAction());
        dispatch({
            type: types.TRANSACTIONS,
            payload: [],
            count: 0
        });
    }
};

const getNetworkFee = (data, setData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("post", Constants.endPoints.getPrice, data);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            dispatch({
                type: types.NETWORK_PRICE,
                payload: res.data,
            });
            setData((prev) => ({
                ...prev,
                gasFees: res.data.networkFee.toFixed(2),
                amountReceived: res.data.cryptoAmount,
            }));
        } else {
            dispatch(hideLoaderAction());
            setData((prev) => ({
                ...prev,
                gasFees: 0,
                amountReceived: 0,
            }));
        }
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

const validateWalletAddress = (data) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("post", Constants.endPoints.validateWalletAddress, data);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch({
                type: types.VALIDATE_WALLET_ADDRESS,
                payload: res.data,
            });
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch({
                type: types.VALIDATE_WALLET_ADDRESS,
                payload: false,
            });
        }
    } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch({
            type: types.VALIDATE_WALLET_ADDRESS,
            payload: false,
        });
    }
};

export { getTransactions, getNetworkFee, validateWalletAddress };
