import React from "react";
import BlurEffect from "../components/BlurEffect";
import Footer from "../layout/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="">
        <div className="bgprivacy-img">
          <div className="imgcontent flex justify-content-center align-items-center ">
            <h1 className="text-primary font-semibold text-40 ">
              Privacy Policy
            </h1>
          </div>
        </div>
        <div className="relative ">
          <BlurEffect right="0%" top="-8%" />
          <BlurEffect left="-3%" top="0%" />
          <div className="container  p-3 lg:px-7 lg:py-8  ">
            <h3 className="text-primary">1. Introduction:</h3>
            <p>
              Tinbox Transfers Ltd. is committed to protecting the privacy and
              security of your personal information. This Privacy Policy
              outlines how we collect, use, disclose, and safeguard your
              personal information in compliance with the Personal Information
              Protection and Electronic Documents Act (PIPEDA) and other
              relevant privacy laws.
            </p>
            <h3 className="text-primary">2. Information We Collect:</h3>
            <p>
              We may collect the following types of personal information when
              you use our services: <br /> - Full name <br />- Contact
              information (address, email, phone number) <br /> -
              Government-issued identification (e.g., passport, driver's
              license) - Financial information (e.g., bank account details){" "}
              <br /> - Transaction history <br /> - Cookies and usage data (see
              "Cookies and Tracking Technologies" section below)
            </p>
            <h3 className="text-primary">Purpose of Data Collection:</h3>
            <p>
              To enhance transparency, we explicitly state the purpose for
              collecting each type of personal information: <br /> <br /> -{" "}
              <span className="font-semibold">Full Name: </span> To verify your
              identity and comply with KYC and AML regulations. <br /> -{" "}
              <span className="font-semibold">Contact Information:</span> To
              provide you with our services, communicate account updates, and
              comply with legal requirements. <br />-{" "}
              <span className="font-semibold">
                Government-Issued Identification and Financial Information:
              </span>{" "}
              For identity verification, transaction processing, and adherence
              to legal and regulatory standards. <br /> -{" "}
              <span className="font-semibold">Transaction History: </span>
              To improve our services, enhance user experience, and prevent
              fraud.
            </p>
            <h3 className="text-primary">3. How We Use Your Information:</h3>
            <p>
              We use your personal information for the following purposes:{" "}
              <br /> <br />- To verify your identity and comply with KYC and AML
              regulations. <br />- To provide you with our services and process
              transactions. <br /> - To communicate with you about your account
              and updates. <br /> - To improve our services and enhance user
              experience. <br /> - To comply with legal and regulatory
              requirements. <br /> - To protect the security of our platform and
              prevent fraud.
            </p>
            <h3 className="text-primary">4. Consent:</h3>
            <p>
              By using our services, you consent to the collection, use, and
              disclosure of your personal information as described in this
              Privacy Policy. You may withdraw your consent at any time, subject
              to legal or contractual restrictions.
            </p>
            <h3 className="text-primary">
              5. Disclosure of Personal Information:
            </h3>
            <p>
              We may disclose your personal information to: <br /> <br />-
              Regulatory authorities as required by law. <br /> - Our service
              providers and partners who assist us in delivering our services.{" "}
              <br /> - Legal and financial advisors in the event of a merger,
              acquisition, or other business transaction. <br /> - With your
              explicit consent, to other parties for specific purposes. <br />{" "}
              <h3 className="text-primary">
                {" "}
                1. Cookies and Tracking Technologies:
              </h3>{" "}
              We may use cookies and similar tracking technologies to collect
              information about your interactions with our website. You can
              manage your cookie preferences through your browser settings.
            </p>
            <h3 className="text-primary">6. Data Security:</h3>
            <p>
              We implement reasonable security measures, including encryption,
              access controls, and regular security audits, to protect your
              personal information from unauthorized access, disclosure, or
              alteration. However, no method of transmission over the internet
              is completely secure, and we cannot guarantee absolute security.
            </p>
            <h3 className="text-primary">7. Access and Correction:</h3>
            <p>
              You have the right to access, correct, or update your personal
              information. To do so, please contact us at{" "}
              <a
                href="mailto:support@tinbox.app"
                className="text-white underline"
              >
                support@tinbox.app
              </a>{" "}
              We will respond to your request within a reasonable timeframe.
            </p>
            <h3 className="text-primary">8. Retention:</h3>
            <p>
              We retain your personal information only for as long as necessary
              to fulfill the purposes outlined in this Privacy Policy or as
              required by applicable laws.
            </p>
            <h3 className="text-primary">9. Changes to this Privacy Policy:</h3>
            <p>
              We may update this Privacy Policy to reflect changes in our
              practices or legal requirements. We will notify you of any
              material changes by posting a revised version on our website and
              sending an email notification if the changes significantly affect
              the way your data is handled.
            </p>
            <h3 className="text-primary">10. Contact Us:</h3>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              the handling of your personal information, please contact us at{" "}
              <a
                href="mailto:legal@tinbox.app"
                className="text-white underline"
              >
                legal@tinbox.app
              </a>{" "}
              . For additional contact options, you can reach us by mail at
            </p>
            <p className="m-0 py-0">
              1800-3305 AVE <br /> SW TOWER 1 <br /> Calgary, AB
              <br /> CANADA T2P0L4
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-secondary">
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
