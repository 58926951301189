import React from "react";
import { Header } from "./Header";
import { MobileFooter } from "./MobileFooter";

export default function Index({ children, socket }) {
  return (
    <>
      <div className="container-fluid header relative z-5">
        <Header socket={socket} />
      </div>
      <div className="flex flex-column justify-content-between">
        <div className="main-container" style={{ minHeight: "80vh" }}>
          {children}
        </div>
        <div className="container-fluid bg-secondary bottom-0 ">
          <MobileFooter />
        </div>
      </div>
    </>
  );
}
