export const types = {
  // Common Types
  USER_PROFILE: "USER_PROFILE",
  USER_DETAILS: "USER_DETAILS",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_TOAST: "SHOW_TOAST",
  RESET_STATE: "RESET_STATE",
  PASSWORD_LOCK: "PASSWORD_LOCK",

  // Transactions //
  TRANSACTIONS: "TRANSACTIONS",
  NETWORK_PRICE: "NETWORK_PRICE",

  NOTIFICATIONS: "NOTIFICATIONS",

  SOCIAL_LOGIN: "SOCIAL_LOGIN",
  GAS_FEES: "GAS_FEES",
  VALIDATE_WALLET_ADDRESS: "VALIDATE_WALLET_ADDRESS",

  ADMIN_DETAILS: "ADMIN_DETAILS"
};
