import { types } from "../types/types";
const intitalState = {
    allTransactions: {
        transactions: [],
        count: 0
    },
    networkPrice: {},
    isValidWalletAddress: false
};

const transactionRuducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.TRANSACTIONS:
            return {
                ...state,
                allTransactions: { transactions: action.payload, count: action.count },
            };
        case types.NETWORK_PRICE:
            return {
                ...state,
                networkPrice: action.payload,
            };
        case types.VALIDATE_WALLET_ADDRESS:
            return {
                ...state,
                isValidWalletAddress: action.payload.isValidWalletAddress
            }
        case types.RESET_STATE:
            return intitalState
        default:
            return { ...state };
    }
};

export default transactionRuducer;