import React from "react";
import { useNavigate } from "react-router-dom";

export const MobileFooter = () => {
  const navigate = useNavigate();
  const GoTOP = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="py-5  bg-black flex align-items-center  justify-content-center block lg:hidden ">
        <div className=" flex flex-column align-items-center  justify-content-center ">
          <small
            className="cursor-pointer"
            onClick={() => {
              navigate("/privacy-policy");
              GoTOP();
            }}
          >
            Privacy Policy
          </small>
          <small
            className="cursor-pointer"
            onClick={() => {
              navigate("terms&conditions");
              GoTOP();
            }}
          >
            Terms & Condition
          </small>
          <small className="">©2023 tinbox. All rights reserved</small>
        </div>
      </div>
    </>
  );
};
