import { types } from "../types/types";
const intitalState = {
    notifications: {}
};

const notificationRuducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            };
        default:
            return { ...state };
    }
};

export default notificationRuducer;