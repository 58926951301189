import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import { CustomInput } from "../../components/InputFields/CustomInput";
import icons from "../../utils/icons";
import { PasswordInput } from "../../components/InputFields/PasswordInput";
import { showToast } from "../../redux/actions/toastAction";
import { validEmail } from "../../utils/regex/regex";
import constants from "../../utils/constants";
import api from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "../../services/authService";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import useDebounce from "../../utils/hooks";
import { checkPasswordLock, editUser } from "../../redux/actions/userActions";
import moment from "moment";
import {
  hideLoaderAction,
  showLoaderAction,
} from "../../redux/actions/loaderAction";

const SignIn = () => {
  const { Logo, GoogleIcon } = icons();
  const navigate = useNavigate();

  const [isError, setIsError] = useState({
    email: "",
    password: "",
  });

  const [passwordTryError, setPasswordTryError] = useState("");
  const [passwordLockTime, setPasswordLockTime] = useState();
  const [timer, setTimer] = useState();
  const dispatch = useDispatch();

  const [email, setEmail] = useDebounce(500, "");

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    let counter;
    if (passwordLockTime && passwordLockTime > 0) {
      counter = setTimeout(
        () => setPasswordLockTime(passwordLockTime - 1),
        1000
      );

      let duration = moment
        .utc(moment.duration(passwordLockTime, "seconds").as("milliseconds"))
        .format("mm:ss");

      setTimer(duration);
    } else if (passwordLockTime <= 0) {
      setTimer();
      clearTimeout(counter);
      setPasswordLockTime();
      setPasswordTryError();
    }
  }, [passwordLockTime]);

  const handleChange = (name) => (event) => {
    if (name === "email") setEmail(event.target.value);
    setData({ ...data, [name]: event.target.value });
  };

  useEffect(() => {
    dispatch(
      checkPasswordLock(
        email ? email : data.email,
        setPasswordTryError,
        setPasswordLockTime
      )
    );
    if (!passwordLockTime) setTimer();
  }, [email]);

  let errors = {
    email: false,
    password: false,
  };

  const Validations = async () => {
    if (data.email === "" || !data.email.length) {
      errors.email = "Enter your Email";
    } else if (!validEmail.test(data.email)) {
      errors.email = "Enter your valid Email";
    }
    if (!data.password) {
      errors.password = "Enter your Password";
    }
    setIsError(errors);
    return errors;
  };

  const Login = async () => {
    dispatch(
      checkPasswordLock(data.email, setPasswordTryError, setPasswordLockTime)
    );

    if (passwordLockTime) {
      return;
    } else {
      dispatch(showLoaderAction());

      const res = await api("post", constants.endPoints.Login, data);
      if (res.success) {
        dispatch(hideLoaderAction());
        const email = res.data.email;
        const token = JSON.stringify(res.data.token);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("token", token);
        navigate("/dashboard");
      } else {
        dispatch(hideLoaderAction());
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(
          checkPasswordLock(email, setPasswordTryError, setPasswordLockTime)
        );
      }
    }
  };

  // Google Login

  const googleLogin = async (decoded) => {
    const userData = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${decoded.access_token}`,
        },
      }
    );

    const res = await api(
      "post",
      constants.endPoints.socialSignUp,
      userData.data
    );
    if (res.success) {
      const email = res.data.email;
      const token = JSON.stringify(res.data.token);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("token", token);
      navigate("/dashboard");
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };
  const googleLoginFun = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      // const decoded = jwtDecode(credentialResponse.credential);
      googleLogin(credentialResponse);
    },
  });

  const handleSubmit = async () => {
    let errors = await Validations();
    if (!errors.email && !errors.password) Login();
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="">
      <div className="grid p-0 m-0">
        <div className="md:col-6 bgsign-image">
          <div className="flex flex-column align-items-center px-4 lg:px-0 w-12 lg:w-9 ">
            <div className="">
              <h3 className="lg:text-6xl text-30 text-primary font-medium lg:line-height-3 m-0">
                Exchange the Crypto in Mintutes
              </h3>
              <p className="text-sm md:text-base lg:py-2 w-12 lg:w-9">
                Welcome to TINBOX (Tinbox Transfers Ltd.), where trust meets
                efficiency in the world of currency exchange. Born out of a deep
                understanding of P2P platforms, we've evolved beyond being
                active merchants to become your trusted ally in the realm of
                stablecoin transactions. No more alerts from your financial
                institution of fraud concerns.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:col-5 col-12 p-0 lg:mt-8 mb-8 m-auto flex flex-column justify-content-center  ">
          <div className="surface-500 border-round-2xl lg:px-6 px-4 ">
            <div className="text-center pt-6 pb-3">
              <small style={{ width: "60px", height: "27px" }}>
                <Logo />
              </small>
            </div>
            <h3 className="lg:text-3xl text-26 font-medium text-primary my-2">
              Welcome Back!
            </h3>
            <span className="text-gray-300 text-sm">
              Enter the information you entered Dashboard
            </span>
            <div className="my-3">
              <CustomInput
                id={"email"}
                onChange={handleChange("email")}
                name={"email"}
                placeholder="Email"
                label="Email"
                value={data.email}
                required
                autoFocus={false}
              ></CustomInput>
              {isError?.email && (
                <p className="text-red-600 text-xs mt-1 ml-1">
                  {isError?.email}
                </p>
              )}
            </div>
            <div className="">
              <PasswordInput
                onChange={handleChange("password")}
                value={data.password}
                type="password"
                placeholder="Password"
                required
                icon="pi-eye"
                className=""
                label="Password"
              ></PasswordInput>
              {isError?.password && (
                <p className="text-red-600 text-xs mt-1 ml-1">
                  {isError?.password}
                </p>
              )}
              {passwordTryError && (
                <p className="text-red-600 text-xs mt-1 ml-1">
                  {passwordTryError} <br /> {timer} {timer ? "minutes" : ""}
                </p>
              )}
            </div>
            <div className="text-sm flex justify-content-end my-3">
              <Link to={"/reset-password"} className="text-white">
                Forgot your Password ?
              </Link>
            </div>
            <div className="col-12  mx-0 px-0">
              <ButtonComponent
                onClick={handleSubmit}
                size=""
                label="Login"
                className="col-12"
                disable={passwordLockTime && passwordLockTime > 0}
              ></ButtonComponent>
            </div>
            <div
              onClick={() => googleLoginFun()}
              className="p-3 cursor-pointer  flex align-items-center justify-content-center border-1 border-gray-300 border-round-lg my-2"
            >
              <div className="mx-2 flex align-items-center justify-content-center ">
                <GoogleIcon />
              </div>
              Continue with Google
            </div>

            <div className="text-sm my-5  text-center">
              <span className="text-white ">Don't have an account?</span>
              <Link
                to={"/register"}
                className="text-primary text-semibold mx-2"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
