import KYC from "../pages/KYC/Index";
import Transactions from "../pages/Transactions";
import CreateNewPassword from "../pages/resetPassword/CreateNewPassword";
import Dashboard from "../pages/Dashboard";

export const PrivateRoutes = [
  {
    path: "/transactions",
    name: "Transactions",
    element: <Transactions />,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    element: <Dashboard />,
  },
  // {
  //   path: "/create-password",
  //   name: "CreateNewPassword",
  //   element: <CreateNewPassword />,
  // },
  {
    path: "/kyc-verification",
    element: <KYC />,
    name: "KYC",
  },
  {
    path: "",
    name: "",
    element: "",
  },
];
