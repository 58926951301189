import React from "react";
import { InputTextarea } from "primereact/inputtextarea";

const CustomTextArea = ({
  value,
  onChange,
  rows,
  cols,
  label,
  placeholder,
  className,
}) => {
  return (
    <div className="">
      <div className="flex flex-column p-1 ">
        <label htmlFor="" className="text-white  font-medium">
          {label}
        </label>
        <InputTextarea
          autoResize
          value={value}
          onChange={onChange}
          rows={rows}
          cols={cols}
          placeholder={placeholder}
          className={`input-bg p-inputtext-sm  border-none border-round-md ${className}`}
        />
      </div>
    </div>
  );
};

export default CustomTextArea;
