import axios from "axios";
import constants from "../utils/constants";

const api = (method, urlEndPoint, data, header) =>
  new Promise((myResolve) => {
    const token = JSON.parse(sessionStorage.getItem("token"));
    // let headers = {
    //   // Authorization:
    //     // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGQwYTQwNTY5OTAzMjAxMDc4NzNjYTYiLCJpYXQiOjE2OTI3NzQzNTAsImV4cCI6MTY5NTM2NjM1MH0.eIL47g8Yikld-RktrHwmkGyIiTtK197Q1x-HlQWNNiI",
    //   "Content-Type": !header ? "application/json" : header,
    // };

    let headers = {
      Authorization: `Bearer ${token}`,
      // Authorization:
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGQwYTQwNTY5OTAzMjAxMDc4NzNjYTYiLCJpYXQiOjE2OTI3NzQzNTAsImV4cCI6MTY5NTM2NjM1MH0.eIL47g8Yikld-RktrHwmkGyIiTtK197Q1x-HlQWNNiI",
      "Content-Type": !header ? "application/json" : header,
    };
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };

    axios({
      method: method,
      url: constants.base_url + urlEndPoint,
      data,
      headers,
    })
      .then((response) => {
        myResolve({
          message: response.data.message,
          data: response.data.data,
          success: response.data.success,
          count: response.data.count,
        });
      })
      .catch((err) => {
        myResolve({
          message: err.response.data.message,
          data: err.response.data.data,
          success: err.response.data.success,
        });
      });
  });

export default api;
