import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import icons from "../../utils/icons";
import { useDispatch } from "react-redux";
import { showLoaderAction } from "../../redux/actions/loaderAction";
import constants from "../../utils/constants";
import api from "../../services/api";
import { showToast } from "../../redux/actions/toastAction";
import Inquiry from "../../components/Persona/Persona.jsx";
import { useState } from "react";

const KYC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { state } = useLocation();
  const [showKYCVerificationTemplate, setShowKYCVerificationTemplate] =
    useState(false);

  const kycVerification = async (data) => {
    const res = await api("post", constants.endPoints.kycVerfication, data);
    if (res.success) {
      dispatch(showToast({ severity: "success", summary: res.message }));
      navigate("/dashboard");
      return setShowKYCVerificationTemplate(false);
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

  const navigateToKycSteps = () => {
    dispatch(showLoaderAction());
    return setShowKYCVerificationTemplate(true);
  };

  const { KycverifyIcon } = icons();
  return (
    <>
      <div className=" ">
        <div className="grid p-0 m-0">
          <div className="md:col-6 bgsign-image">
            <div className="flex flex-column align-items-center px-4 lg:px-0">
              <div className="">
                <h3 className="lg:text-6xl text-30 text-primary font-medium lg:line-height-3 m-0">
                  Exchange the Crypto <br />
                  in Mintutes
                </h3>
                <p className="text-sm md:text-base lg:py-2 ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do
                  <br /> eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. <br /> Ut enim ad minim veniam, quis nostrud
                  exercitation
                </p>
              </div>
            </div>
          </div>

          <div className="lg:col-5 col-12 lg:mt-8 mb-8 m-auto flex flex-column justify-content-center  ">
            {showKYCVerificationTemplate ? (
              <Inquiry
                setShowKYCVerificationTemplate={setShowKYCVerificationTemplate}
                finishFnxn={kycVerification}
              />
            ) : (
              <div className="surface-500 border-round-2xl lg:px-6 px-4 lg:py-7 py-5">
                <>
                  <h3 className="lg:text-3xl text-26 font-medium text-primary my-2">
                    Verify KYC
                  </h3>
                  <small>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et
                  </small>
                  <div className="text-center  ">
                    <small style={{ width: "60px", height: "27px" }}>
                      <KycverifyIcon />
                    </small>
                  </div>
                  <div className="col-12   mx-0 px-0">
                    <ButtonComponent
                      onClick={navigateToKycSteps}
                      label="Start KYC"
                      className="col-12"
                    ></ButtonComponent>
                  </div>
                  <div className="text-center">
                    <small
                      className="my-3 text-center cursor-pointer"
                      onClick={() => navigate("/dashboard")}
                    >
                      Skip
                    </small>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default KYC;
