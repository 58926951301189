import { Dropdown } from "primereact/dropdown";
import React from "react";

export function CustomDropdown({
  placeholder,
  optionLabel,
  options,
  onChange,
  label,
  height,
  name,
  value,
  disabled,
}) {
  return (
    <div className="">
      <label htmlFor=" " className="font-medium ">
        {label}
      </label>
      <div className="p-inputgroup w-full flex-1 input-outline">
        <Dropdown
          options={options}
          optionLabel={optionLabel}
          placeholder={placeholder}
          style={{ height: height }}
          name={name}
          disabled={disabled}
          onChange={(e) =>
            onChange &&
            onChange({
              ...e,
              name: e.target.name,
              value: e.target.value,
              label: label,
            })
          }
          value={value}
          className="input-bg border-none text-white w-full align-items-center"
        />
      </div>
    </div>
  );
}
