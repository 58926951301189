import React from "react";
import { Checkbox } from "primereact/checkbox";
export default function CustomCheckbox({
  onChange,
  value,
  name,
  id,
  label,
  extraClass,
}) {
  return (
    <div className={`flex align-items-center ${extraClass}`}>
      <Checkbox
        className="border-none border-round-xl"
        inputId={id ? id : name}
        name={name}
        value={value}
        onChange={onChange}
        checked={value}
      />
      <label htmlFor={id ? id : name} className="ml-2 text-sm">
        {label}
      </label>
    </div>
  );
}
