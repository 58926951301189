import React from "react";
import { Password } from "primereact/password";

export function PasswordInput({ placeholder, onChange, label, required }) {
  // const { EyeSlashImg, EyeImg } = icons();
  // const [togglePassword, setTogglePassword] = useState(false);
  return (
    <div className="p-1">
      <label htmlFor="" className="">
        {label}
      </label>
      {/* {required ? <span className="text-red-400">*</span> : ""} */}
      <div className=" p-inputgroup flex-1 ">
        <Password
          toggleMask
          placeholder={placeholder}
          className="border-none"
          onChange={onChange}
          feedback={false}
          // type={!togglePassword ? "password" : "text"}
        />
        {/* <span
          className="p-inputgroup-addon"
          onClick={() => setTogglePassword((prev) => !prev)}
        >
          {!togglePassword ? <EyeImg /> : <EyeSlashImg />}
        </span> */}
      </div>
    </div>
  );
}
