import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../utils/constants";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

const getGasFeesCurrencyList = (setLoading) => async (dispatch) => {

    dispatch(showLoaderAction());

    const res = await api("get", Constants.endPoints.gasFees);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            dispatch({
                type: types.GAS_FEES,
                payload: res.data
            });
        } else {
            dispatch({
                type: types.GAS_FEES,
                payload: []
            });
        }
    } else {
        dispatch(hideLoaderAction());
        dispatch({
            type: types.GAS_FEES,
            payload: []
        });
    }
};

const updateGasFees = (id, data, setLoading) => async (dispatch) => {
    setLoading(true);

    const payload = {
        ...data,
    };

    const res = await api("put", Constants.END_POINT.UPDATE_GAS_FEES + id, payload);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(getGasFeesCurrencyList(setLoading));
    } else {
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
};

export { getGasFeesCurrencyList, updateGasFees };
