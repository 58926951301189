import React, { useState, useEffect } from "react";
import ButtonComponent from "../components/Buttons/button";
import { CustomInput } from "../components/InputFields/CustomInput";
import { useLocation, useNavigate } from "react-router-dom";
import CustomCheckbox from "../components/InputFields/CustomCheckbox";
import constants from "../utils/constants";
import api from "../services/api";
import { CustomDropdown } from "../components/InputFields/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { fieldValidator, formValidator } from "../utils/validations";
import {
  hideLoaderAction,
  showLoaderAction,
} from "../redux/actions/loaderAction";
import TransactionSuccessModal from "../components/Models/TransactionSuccessModal";
import { showToast } from "../redux/actions/toastAction";
import {
  getNetworkFee,
  validateWalletAddress,
} from "../redux/actions/transactionsAction";
import { validEmail } from "../utils/regex/regex";
import { getGasFeesCurrencyList } from "../redux/actions/gasFeesAction";
import { getAdminDetails, getUserInfo } from "../redux/actions/userActions";
import useDebounce from "../utils/hooks";
import TransactionSuccess from "../components/TransactionSuccess/TransactionSuccess";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [walletAddress, setWalletAddress] = useDebounce(500, "");

  const location = useLocation();

  const [isKYCVerified, setIsKYCVerified] = useState(null);
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [successData, setSuccessData] = useState({});

  let { isValidWalletAddress } = useSelector((state) => state.transactions);

  let { gasFees } = useSelector((state) => state.gasFees);

  const { userDetails, adminDetails } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getGasFeesCurrencyList());
    dispatch(getAdminDetails());
    dispatch(getUserInfo());
  }, [dispatch]);

  const checkUserKYCVerification = async () => {
    const res = await api("get", constants.endPoints.checkKYCVerification);
    if (res.success) {
      setIsKYCVerified(res.data.kycVerified);
    } else {
      console.log(res.message);
    }
  };

  const [confirmWalletOwner, setConfirmWalletOwner] = useState(false);
  const [transactionSuccessModal, setTransactionSuccessModal] = useState(false);

  const cryptoCurrencies = constants.cryptoCurrencies;

  const [transactionForm, setTransactionFormData] = useState({
    walletAddress: "",
    network: "",
    email: userDetails?.email,
    gasFees: "",
    amountPaid: location.state ? location.state.amountPaid.toString() : "",
    paidAmountCurrency: location.state
      ? location.state.paidAmountCurrency
      : "USDT",
    amountReceived: location.state
      ? location.state.amountReceived.toString()
      : "",
    receivedAmountCurrency: location.state
      ? location.state.receivedAmountCurrency
      : "CAD",
  });

  useEffect(() => {
    if (
      transactionForm.walletAddress &&
      transactionForm.paidAmountCurrency &&
      transactionForm.receivedAmountCurrency &&
      transactionForm.network
    ) {
      const data = {
        walletAddress: transactionForm.walletAddress,
        currency:
          transactionForm.paidAmountCurrency === "CAD"
            ? transactionForm.receivedAmountCurrency
            : transactionForm.paidAmountCurrency,
        network: transactionForm.network,
      };
      dispatch(validateWalletAddress(data));
    }
  }, [walletAddress]);

  const resetTransactionForm = () => {
    setTransactionFormData({
      walletAddress: "",
      network: "",
      email: userDetails.email,
      gasFees: "",
      amountPaid: "",
      paidAmountCurrency: "USDT",
      amountReceived: "",
      receivedAmountCurrency: "CAD",
    });
  };

  const formValidationKeyLabels = {
    walletAddress: "wallet address",
    network: "network",
    // email: "email",
  };

  let [errors, setErrors] = useState({});

  const handleChange = async ({ name, value, label }) => {
    const fieldValidation = await fieldValidator(name, value, errors, label);
    setErrors({ ...fieldValidation });

    if (name === "amountPaid" && !value) transactionForm.amountReceived = "";
    if (name === "paidAmountCurrency" && value === "CAD")
      transactionForm.receivedAmountCurrency = "USDT";

    if (name === "paidAmountCurrency" && value !== "CAD")
      transactionForm.receivedAmountCurrency = "CAD";

    if (name === "walletAddress") setWalletAddress(value);

    setTransactionFormData({ ...transactionForm, [name]: value });
  };

  useEffect(() => {
    if (
      transactionForm.amountPaid &&
      transactionForm.network &&
      transactionForm.paidAmountCurrency &&
      transactionForm.receivedAmountCurrency &&
      transactionForm.paidAmountCurrency &&
      transactionForm.receivedAmountCurrency
    ) {
      const data = {
        payCurrency: transactionForm.paidAmountCurrency,
        receiveCurrency: transactionForm.receivedAmountCurrency,
        tradeType:
          transactionForm.paidAmountCurrency === "CAD" &&
          transactionForm.receivedAmountCurrency !== "USD"
            ? "BUY"
            : "SELL",
        network: transactionForm.network,
        amount: transactionForm.amountPaid,
      };
      if (walletAddress) {
        const data = {
          walletAddress: transactionForm.walletAddress,
          currency:
            transactionForm.paidAmountCurrency === "CAD"
              ? transactionForm.receivedAmountCurrency
              : transactionForm.paidAmountCurrency,
          network: transactionForm.network,
        };
        dispatch(validateWalletAddress(data));
      }
      getGasFee(data);
    } else if (transactionForm.gasFees) {
      setTransactionFormData((prev) => ({
        ...prev,
        gasFees: 0,
      }));
    }
  }, [
    transactionForm.paidAmountCurrency,
    transactionForm.network,
    transactionForm.amountPaid,
    transactionForm.amountPaid,
    transactionForm.paidAmountCurrency,
    transactionForm.receivedAmountCurrency,
  ]);

  const handleSubmit = async () => {
    transactionForm.email = userDetails.email;
    setTransactionFormData(transactionForm);

    const formErrors = await formValidator(
      formValidationKeyLabels,
      transactionForm,
      errors
    );

    if (!validEmail.test(transactionForm.email)) {
      formErrors.email = "Enter your valid Email";
    }

    setErrors({ ...formErrors, ...errors });

    if (Object.values(formErrors).length) return;

    if (!transactionForm.amountPaid) {
      return dispatch(
        showToast({
          severity: "error",
          summary: "Please enter amount to be paid by you.",
        })
      );
    }

    if (!transactionForm.paidAmountCurrency) {
      return dispatch(
        showToast({
          severity: "error",
          summary: "Please select pay amount currency.",
        })
      );
    }

    if (!transactionForm.receivedAmountCurrency) {
      return dispatch(
        showToast({
          severity: "error",
          summary: "Please select receive amount currency.",
        })
      );
    }

    dispatch(showLoaderAction());
    const adminWalletAddress = adminDetails.walletAddresses.find(
      (item) =>
        item.network.toLowerCase() === transactionForm.network.toLowerCase()
    ).address;

    const res = await api("post", constants.endPoints.createTransaction, {
      ...transactionForm,
      adminWalletAddress: adminWalletAddress,
      eTransferMail: adminDetails.eTransferMail,
    });
    if (res.success) {
      setSuccessData({
        tradeType:
          transactionForm.paidAmountCurrency === "CAD" ? "BUY" : "SELL",
        amount: transactionForm.amountPaid,
        payCurrency: transactionForm.paidAmountCurrency,
        receiveCurrency: transactionForm.receivedAmountCurrency,
        userWalletAddress: transactionForm.walletAddress,
        adminWalletAddress: adminWalletAddress,
        eTransferMail: adminDetails.eTransferMail,
      });
      setIsRequestSubmitted(true);
      resetTransactionForm();
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "success", summary: res.message }));
      // setTransactionSuccessModal(true);
    } else {
      dispatch(hideLoaderAction());
      dispatch(
        showToast({
          severity: "error",
          summary: res.message ? res.message : "Something went wrong.",
        })
      );
    }
  };

  const getGasFee = async (data) => {
    if (data.tradeType === "BUY") {
      if (data.receiveCurrency) {
        const payload = {
          currency: data.receiveCurrency,
          tradeType: "BUY",
          network: data.network,
          amount: data.amount,
        };
        return dispatch(getNetworkFee(payload, setTransactionFormData));
      }
    } else {
      const currencyPair = `${data.payCurrency}-${data.receiveCurrency}`;
      gasFees.map((fee) => {
        const feeCurrencyPair = `${fee.firstCurrency}-${fee.secondCurrency}`;
        if (currencyPair === feeCurrencyPair) {
          setTransactionFormData((prev) => ({
            ...prev,
            // gasFees: (
            //   (data.amount * (fee.fees ? fee.fees : fee.transactionFee)) /
            //   100
            // ).toFixed(2),
            amountReceived:
              data.amount * fee.exchangePricePerUnit -
              (fee.fees ? fee.fees : fee.transactionFee),
            // (
            //   (data.amount * fee.fees ? fee.fees : fee.transactionFee) / 100
            // ).toFixed(2),
          }));
        }
      });
    }
  };

  useEffect(() => {
    checkUserKYCVerification();
  }, []);

  return (
    <>
      <TransactionSuccessModal
        transactionSuccessModal={transactionSuccessModal}
        setTransactionSuccessModal={setTransactionSuccessModal}
      />

      <div className="container">
        <div className="flex flex-wrap  lg:my-5 lg:py-3 py-2 ">
          <div className="col-12 md:col-6 lg:col-6 flex flex-column ">
            {!isKYCVerified ? (
              <div className=" surface-500 border-round-2xl lg:p-4 p-3">
                <h3 className="text-22   my-0">
                  You have not verified your KYC please verify to start
                  transaction
                </h3>
                <div className="mt-3">
                  <ButtonComponent
                    style={{ height: "35px" }}
                    onClick={() =>
                      navigate("/kyc-verification", {
                        state: { email: sessionStorage.getItem("email") },
                      })
                    }
                    label={"Get Started"}
                    className=""
                  ></ButtonComponent>
                </div>
              </div>
            ) : (
              <div className=" surface-500 border-round-2xl lg:p-4 p-3">
                <h3 className="text-22   my-0">
                  How to Sell Stablecoin On Tinbox
                </h3>
              </div>
            )}
            <div className="col flex align-items-center surface-500 border-round-2xl lg:p-4 p-3 mt-2">
              <div className=" flex flex-column justify-content-between   ">
                <h4 className="font-normal mt-0">
                  <span className="text-primary">Step 1.</span> Begin by
                  selecting your preferred network.
                </h4>
                <h4 className="font-normal">
                  <span className="text-primary">Step 2.</span> Choose the
                  currencies you wish to exchange and input the corresponding
                  amounts.
                </h4>
                <h4 className="font-normal">
                  <span className="text-primary">Step 3.</span> Once you've
                  selected the network and entered the currency amounts, the
                  associated gas fees will be displayed.{" "}
                </h4>
                <h4 className="font-normal mb-0">
                  <span className="text-primary">Step 4.</span> Conclude the
                  process by entering your wallet address and clicking on the
                  "Swap" button.{" "}
                </h4>
                <h4 className="font-normal mb-0">
                  <span className="text-primary">Step 5.</span> Follow the
                  on-screen instructions and check your email for additional
                  guidance.{" "}
                </h4>
              </div>
            </div>
          </div>
          <div className="md:col-6 col-12 relative">
            <div
              className={`absolute ${!isKYCVerified ? "blur-div" : ""}`}
            ></div>
            <div className="surface-500 border-round-2xl lg:p-4 p-3">
              {isRequestSubmitted ? (
                <>
                  <div
                    className="flex text-primary justify-content-end text-26 cursor-pointer"
                    onClick={() => setIsRequestSubmitted(false)}
                  >
                    x
                  </div>
                  <TransactionSuccess
                    successData={successData}
                  ></TransactionSuccess>
                </>
              ) : (
                <>
                  <div className="mt-3 lg:mt-2 p-1 lg:p-2">
                    <div className="mt-3">
                      <CustomDropdown
                        label={"Network"}
                        placeholder="Select Network"
                        disabled={!isKYCVerified}
                        height={"45px"}
                        options={constants.networkOptions}
                        optionLabel={"name"}
                        name="network"
                        value={transactionForm.network}
                        onChange={handleChange}
                      ></CustomDropdown>
                      {errors?.network && (
                        <p className="text-red-600 text-xs mt-1">
                          {errors?.network}
                        </p>
                      )}
                    </div>
                    <br />
                    <p className="text-sl font-semibold my-0">
                      Wallet Information
                    </p>
                    <div className="bg-white border-radius-10">
                      <div className="flex p-3 justify-content-between align-items-center pay-sell-container">
                        <div className="">
                          <p className="text-xs text-secondary mb-0">You Pay</p>
                          <div className="mobile-inputsizee m-0 p-0">
                            <CustomInput
                              extraClass={"border-none"}
                              disabled={!isKYCVerified}
                              type={"number"}
                              placeholder="0"
                              style={{ color: "black" }}
                              name="amountPaid"
                              value={transactionForm.amountPaid}
                              onChange={handleChange}
                            ></CustomInput>
                          </div>
                        </div>
                        <div className="relative ">
                          <div className="bg-gray-200 flex align-items-center  p-2 px-4 border-round-3xl gap-3">
                            <p className="text-color m-0 Customdrop ">
                              <CustomDropdown
                                className="bg-transparent"
                                disabled={!isKYCVerified}
                                options={cryptoCurrencies}
                                name="paidAmountCurrency"
                                value={transactionForm.paidAmountCurrency}
                                onChange={handleChange}
                                optionLabel={""}
                              ></CustomDropdown>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex relative">
                        <hr className="w-full" style={{ top: "25%" }} />
                      </div>
                      <div className="flex p-3  justify-content-between align-items-center pay-sell-container">
                        <div className="">
                          <p className="text-xs text-secondary mt-0">You Get</p>
                          <div className="mobile-inputsizee m-0 p-0">
                            <CustomInput
                              disabled
                              type={"number"}
                              style={{ color: "black" }}
                              extraClass={"border-none"}
                              placeholder="0"
                              name={"amountReceived"}
                              value={transactionForm.amountReceived}
                              onChange={handleChange}
                            ></CustomInput>
                          </div>
                        </div>
                        <div className="relative ">
                          <div className="bg-gray-200 flex align-items-center  p-2 px-4 border-round-3xl gap-3  ">
                            <p className="text-color m-0 Customdrop  ">
                              <CustomDropdown
                                disabled={!isKYCVerified}
                                placeholder={""}
                                className="bg-transparent"
                                options={
                                  transactionForm.paidAmountCurrency !== "CAD"
                                    ? ["CAD"]
                                    : cryptoCurrencies.filter(
                                        (item) =>
                                          item !==
                                          transactionForm.paidAmountCurrency
                                      )
                                }
                                optionLabel={""}
                                name="receivedAmountCurrency"
                                value={transactionForm.receivedAmountCurrency}
                                onChange={handleChange}
                              ></CustomDropdown>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {transactionForm.amountPaid &&
                    transactionForm.amountReceived ? (
                      <p className="text-xs pl-2">
                        Estimated price: {transactionForm.amountPaid}{" "}
                        {transactionForm.paidAmountCurrency} ≈{" "}
                        {transactionForm.amountReceived.toFixed(2)}{" "}
                        {transactionForm.receivedAmountCurrency}
                      </p>
                    ) : null}
                  </div>
                  <div className="lg:flex m-0 p-0">
                    <div className="lg:col-7 p-0">
                      <CustomInput
                        label={"E-Transfer Email Address"}
                        name="email"
                        disabled
                        value={userDetails.email}
                      ></CustomInput>
                    </div>
                    <div className="lg:col-5 p-0">
                      <CustomInput
                        label={
                          (transactionForm.paidAmountCurrency === "CAD" &&
                            transactionForm.receivedAmountCurrency === "USD") ||
                          (transactionForm.paidAmountCurrency === "USD" &&
                            transactionForm.receivedAmountCurrency === "CAD")
                            ? "Gas/Transaction fees"
                            : "Gas fees"
                        }
                        name="gasFees"
                        value={transactionForm.gasFees}
                        onChange={handleChange}
                        disabled
                      ></CustomInput>
                    </div>
                  </div>
                  <CustomInput
                    label={"Wallet address"}
                    name="walletAddress"
                    value={transactionForm.walletAddress}
                    onChange={handleChange}
                    disabled={!isKYCVerified || !transactionForm.network}
                  ></CustomInput>
                  {errors?.walletAddress && (
                    <p className="text-red-600 text-xs mt-1">
                      {errors?.walletAddress}
                    </p>
                  )}
                  <div className="mt-3 lg:mt-2 p-1 lg:p-2">
                    <div className="mb-4 align-items-center">
                      <CustomCheckbox
                        extraClass={"text-center "}
                        label={"Confirm that I am the owner of this wallet"}
                        value={confirmWalletOwner}
                        onChange={async () => {
                          setConfirmWalletOwner((prev) => !prev);
                          // await formValidations();
                        }}
                      ></CustomCheckbox>
                    </div>
                    <ButtonComponent
                      label="Swap "
                      className={`${
                        !confirmWalletOwner ? "bg-gray-800" : ""
                      } w-full`}
                      onClick={handleSubmit}
                      disable={!confirmWalletOwner || !isValidWalletAddress}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
