import InformationLogo from "../../assets/icons/information-pamphlet.png";
import CopyIcon from "../../assets/icons/copy-icon.png";
import { showToast } from "../../redux/actions/toastAction";
import { useDispatch } from "react-redux";

const TransactionSuccess = ({ successData }) => {
  const dispatch = useDispatch();
  return successData.tradeType === "BUY" ? (
    <>
      <div
        className="flex flex-column border-1 border-gray-600 border-round-2xl lg:px-6 px-2 lg:py-7 py-4 succcessful-transaction-card"
        style={{ margin: "60px" }}
      >
        <div className="flex justify-content-center align-items-center">
          <img
            src={InformationLogo}
            alt=""
            style={{ height: "36px", width: "36px" }}
          />
        </div>
        <small className="text-22 text-center">Instructions</small>

        <div className="mt-5">
          <small className="text-30 font-normal">
            Send{" "}
            <span className="text-primary font-bold">{successData.amount}</span>{" "}
            {successData.payCurrency}-{successData.receiveCurrency}
          </small>
        </div>
        <div>
          <h4 className="text-primary font-normal mb-0">To: Email address</h4>
          <h3 className="font-normal mt-0">{successData.eTransferMail}</h3>
        </div>
        <div>
          <h5 className="font-normal mb-0">
            <span className="text-primary">Note:</span>{" "}
            <small>We have sent an email to your email address.</small>{" "}
          </h5>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        className="flex flex-column border-1 border-gray-600 border-round-2xl lg:px-6 px-2 lg:py-7 py-4 succcessful-transaction-card"
        style={{ margin: "60px" }}
      >
        <div className="flex justify-content-center align-items-center">
          <img
            src={InformationLogo}
            alt=""
            style={{ height: "36px", width: "36px" }}
          />
        </div>
        <small className="text-22 text-center">Instructions</small>

        <div className="mt-5">
          <small className="text-30 font-normal">
            Send{" "}
            <span className="text-primary font-bold">{successData.amount}</span>{" "}
            {successData.payCurrency}-{successData.receiveCurrency}
          </small>
        </div>
        <div>
          <h4 className="text-primary font-normal mb-0">From: Your Address</h4>
          <h3 className="font-normal mt-0">{successData.userWalletAddress}</h3>
        </div>
        <div>
          <h4 className="text-primary font-normal mb-0">To: Tinbox Address</h4>
          <h3 className="flex font-normal mt-0  ">
            {successData.adminWalletAddress}
            <img
              src={CopyIcon}
              alt=""
              style={{ height: "17px", width: "17px" }}
              className="ml-5 text-center cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(successData.adminWalletAddress);
                dispatch(
                  showToast({
                    severity: "success",
                    summary: "Wallet address copied to clipboard",
                  })
                );
              }}
            />
          </h3>
        </div>
        <div>
          <h5 className="font-normal mb-0">
            <span className="text-primary">Note:</span>{" "}
            <small>We have sent an email to your email address.</small>{" "}
          </h5>
        </div>
      </div>
    </>
  );
};

export default TransactionSuccess;
