import { types } from "../types/types";
const intitalState = {
  profile: {},
  userDetails: {},
  adminDetails: {}
  // passwordLockDetails: {}
};

const userRuducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case types.USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case types.ADMIN_DETAILS:
      return {
        ...state,
        adminDetails: action.payload

      };
    // case types.PASSWORD_LOCK:
    //   return {
    //     ...state,
    //     passwordLockDetails: { count: action.payload.passwordLockCount, time: action.payload.passwordLockTime },
    //   }
    default:
      return { ...state };
  }
};

export default userRuducer;
