import React from "react";
import { Dialog } from "primereact/dialog";
import ButtonComponent from "../Buttons/button";
export default function TransactionSuccessModal({
  transactionSuccessModal,
  setTransactionSuccessModal,
}) {
  return (
    <Dialog
      visible={transactionSuccessModal}
      style={{ width: "50vw" }}
      className="text-center"
      onHide={() => setTransactionSuccessModal(false)}
    >
      <p>
        Your request is under Process. Please view Transaction history page for
        the status of your Request{" "}
      </p>
      <hr className="w-10 m-auto" />
      <ButtonComponent
        size="w-8 mx-auto my-3"
        label="OK"
        style={{ width: "40%" }}
        onClick={() => setTransactionSuccessModal(false)}
      />
    </Dialog>
  );
}
