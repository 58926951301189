import React, { useState } from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import icons from "../../utils/icons";
import { CustomInput } from "../../components/InputFields/CustomInput";
import { showToast } from "../../redux/actions/toastAction";
import { useDispatch } from "react-redux";
import { validEmail } from "../../utils/regex/regex";
import constants from "../../utils/constants";
import api from "../../services/api";
import VerifyCode from "./VerifyCode";

const ResetPassword = () => {
  const { Logo } = icons();
  const [showEmail, setShowEmail] = useState();
  const [showResetPVerificationPage, setShowResetPVerificationPage] =
    useState();
  const [isError, setIsError] = useState({});
  const dispatch = useDispatch();

  const [data, setData] = useState({
    email: "",
    otpType: 2,
  });

  const handleChange = (name) => (event) => {
    setData({ ...data, [name]: event.target.value });
  };

  let errors = {
    email: false,
  };

  const Validations = async () => {
    if (data.email === "" || !data.email.length) {
      errors.email = "Enter your Email";
    } else if (!validEmail.test(data.email)) {
      errors.email = "Enter your valid Email";
    }
    setIsError(errors);
    return errors;
  };

  const ForgotPasswordAPI = async () => {
    const res = await api("post", constants.endPoints.ForgotPassword, data);

    if (res.success) {
      setShowEmail(res.data);
      const email = res.data.email;
      sessionStorage.setItem("email", email);
      setShowResetPVerificationPage(true);
      dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

  const handleSubmit = async () => {
    let errors = await Validations();
    if (!errors.email) ForgotPasswordAPI();
  };

  return (
    <>
      {showResetPVerificationPage ? (
        <VerifyCode
          showEmail={showEmail}
          ForgotPasswordAPI={ForgotPasswordAPI}
        />
      ) : (
        <div className=" ">
          <div className="grid p-0 m-0">
            <div className="md:col-6 bgsign-image">
              <div className="flex flex-column align-items-center px-4 lg:px-0 w-12 lg:w-9 ">
                <div className="">
                  <h3 className="lg:text-6xl text-30 text-primary font-medium lg:line-height-3 m-0">
                    Exchange the Crypto in Mintutes
                  </h3>
                  <p className="text-sm md:text-base lg:py-2 w-12 lg:w-9">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:col-5 col-12 p-0 lg:mt-8 mb-8 m-auto flex flex-column justify-content-center  ">
              <div className="surface-500 border-round-2xl lg:px-6 px-4 py-6">
                <div className="text-center pb-3">
                  <small style={{ width: "60px", height: "27px" }}>
                    <Logo />
                  </small>
                </div>
                <h3 className="lg:text-3xl text-26 font-medium text-primary my-2">
                  Forgot your Password?
                </h3>

                <div className="my-3">
                  <CustomInput
                    placeholder="Email"
                    label="Email"
                    required
                    value={data.email}
                    onChange={handleChange("email")}
                  ></CustomInput>
                  {isError?.email && (
                    <p className="text-red-600 text-xs mt-1">
                      {isError?.email}
                    </p>
                  )}
                </div>

                <div className="col-12  mx-0 px-0">
                  <ButtonComponent
                    onClick={handleSubmit}
                    label="Reset Password"
                    className="col-12  "
                  ></ButtonComponent>
                </div>

                <div className="text-sm mt-4 text-center">
                  <Link to={"/sign-in"} className="text-white mx-2">
                    Back to Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
