import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import icons from "../utils/icons";
import ButtonComponent from "../components/Buttons/button";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import Profile from "../assets/images/profile-img.png";
import { isAuthenticated, logout } from "../services/authService";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  markAsRead,
} from "../redux/actions/notificationAction";
import * as moment from "moment";
import MobileNotification from "./mobileNotification";
import { getUser, getUserInfo } from "../redux/actions/userActions";
import constants from "../utils/constants";
import MyProfileIMG from "../assets/images/profile-img.png";
import Avatar from "react-avatar";

export function Header({ socket }) {
  const { Logo, VerifyUser, LogoutIcon } = icons();
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const menuRight = useRef(null);
  const menuLeft = useRef(null);
  const Notif = useRef(null);

  const dispatch = useDispatch();
  const [showMobileNotifications, setshowMobileNotifications] = useState();
  const [loading, setLoading] = useState(false);
  const { notifications } = useSelector((state) => state.notifications);
  const { userDetails } = useSelector((state) => state.user);

  const unreadNotificationsCount =
    notifications.length &&
    notifications.filter((item) => item.status === "Unread").length
      ? notifications.length
      : 0;

  useEffect(() => {
    socket.on("notificationSent", () => {
      dispatch(getNotifications(setLoading));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket.connected]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  const markNotificationAsRead = (id) => {
    dispatch(markAsRead(id));
  };

  const items = [
    {
      label: "Private Wallet",
      command: () => {
        navigate("/dashboard");
      },
    },
    {
      label: "Existing P2P",
      command: () => {
        navigate("/transactions");
      },
    },
  ];

  const Myprofile = [
    {
      label: "My Profile",
      icon: "pi pi-fw pi-user text-white",
      command: () => {
        navigate("/my-profile");
      },
    },
    {
      label: "Logout",
      icon: <LogoutIcon />,
      command: () => {
        Logout();
      },
    },
  ];
  const Notifi = [
    { label: "Notification" },
    {
      template: () => {
        return (
          <>
            {notifications.length ? (
              notifications.map((notification) => {
                return (
                  <div
                    className={`${
                      notification.status === "Unread"
                        ? "text-white"
                        : "text-gray-600"
                    } text-xs `}
                  >
                    <ul className="p-2" style={{}}>
                      <Tooltip
                        target=".custom-tooltip-notification"
                        position="left"
                      >
                        mark as read
                      </Tooltip>
                      <li
                        className={`${
                          notification.status === "Unread"
                            ? "custom-tooltip-notification"
                            : ""
                        } mb-1 cursor-pointer shadow-3  hover:shadow-8 border-0 border-round-md  p-2 `}
                        onClick={() => markNotificationAsRead(notification._id)}
                      >
                        {notification.message}
                        <div className="flex justify-content-end">
                          {moment(notification.createdAt)
                            .startOf("seconds")
                            .fromNow()}
                        </div>
                      </li>
                    </ul>{" "}
                  </div>
                );
              })
            ) : (
              <div className="text-center text-white"> No notifications </div>
            )}
          </>
        );
      },
    },
  ];
  const Logout = () => {
    navigate("/");
    sessionStorage.clear();
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <div className="  container py-3 overflow-hidden">
      <div className="header-container">
        {isAuthenticated() ? (
          <NavLink onClick={() => setShowMenu(false)} to="/" className="logo">
            <Logo />
          </NavLink>
        ) : (
          <NavLink onClick={() => setShowMenu(false)} to="/" className="logo">
            <Logo />
          </NavLink>
        )}
        <input
          name="hamburger"
          type="checkbox"
          id="hamburger"
          className="hamburger-checkbox"
          checked={showMenu}
        ></input>
        <label
          htmlFor="hamburger"
          onClick={() => setShowMenu(true)}
          className="show-menu-icon"
        >
          <i className="pi pi-bars text-primary text-xl font-bold"></i>
        </label>

        {isAuthenticated() ? (
          <>
            <div className="nav-menu  inline-flex block hidden">
              <NavLink
                onClick={() => setShowMenu(false)}
                className={({ isActive }) =>
                  isActive ? "active-menu nav-link" : "nav-link"
                }
              >
                <div className="flex align-items-center ">
                  <Menu
                    model={items}
                    popup
                    ref={menuLeft}
                    id="popup_menu_left"
                  />
                  <Button
                    label="Trade"
                    iconPos="right"
                    icon="pi pi-angle-down "
                    className="mr-2 bg-transparent text-gray-300 font-normal "
                    onClick={(event) => menuLeft.current.toggle(event)}
                    aria-controls="popup_menu_left"
                    aria-haspopup
                  />
                </div>
              </NavLink>
              <div className="active-color w-full-mobile ">
                <NavLink
                  onClick={() => setShowMenu(false)}
                  className={({ isActive }) =>
                    isActive ? " active-menu nav-link" : "nav-link"
                  }
                  to="/transactions"
                >
                  Transaction History
                </NavLink>
              </div>
              <NavLink
                onClick={() => setShowMenu(false)}
                className={({ isActive }) =>
                  isActive ? "active-menu nav-link" : "nav-link"
                }
              >
                <NavLink className="text-white card flex justify-content-center relative ">
                  <Tooltip target=".custom-target-icon" />

                  <i
                    className="custom-target-icon pi pi-bell p-text-white p-overlay-badge"
                    data-pr-tooltip={
                      unreadNotificationsCount > 0
                        ? `${unreadNotificationsCount} unread notifications`
                        : "No notifications"
                    }
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{
                      fontSize: "1.2rem",
                      cursor: "pointer",
                    }}
                  >
                    {unreadNotificationsCount > 0 ? (
                      <Badge severity="danger"></Badge>
                    ) : null}
                  </i>
                  <div className="flex align-items-center absolute menu-width ">
                    <Menu
                      model={Notifi}
                      popup
                      ref={Notif}
                      id="popup_menu_left"
                      style={{
                        width: "319px",
                        height: "530px",
                        overflow: "auto",
                      }}
                    />
                    <Button
                      iconPos="right"
                      className="mr-2 bg-transparent text-gray-300 font-normal "
                      onClick={(event) => {
                        if (!notifications.length) {
                          dispatch(getNotifications(setLoading));
                        }
                        Notif.current.toggle(event);
                      }}
                      aria-controls="popup_menu_left"
                      aria-haspopup
                    />
                  </div>
                </NavLink>
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-menu nav-link" : "nav-link"
                }
              >
                <div className="flex align-items-center">
                  {userDetails.firstName ? (
                    <Avatar
                      name={`${userDetails.firstName} ${userDetails.lastName}`}
                      round
                      size="40"
                    />
                  ) : (
                    <img
                      src={MyProfileIMG}
                      alt=""
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                  <div className="flex flex-column align-items-start justify-content-start mx-3">
                    <Menu
                      model={Myprofile}
                      popup
                      ref={menuRight}
                      id="popup_menu_right"
                    />
                    <Button
                      label="My Profile"
                      iconPos="right"
                      icon="pi pi-angle-down "
                      className="mr-2 m-0 p-0 font-semibold bg-transparent text-white"
                      onClick={(event) => menuRight.current.toggle(event)}
                      aria-controls="popup_menu_right"
                      aria-haspopup
                    />
                    <div className="flex align-items-center">
                      <span>
                        <VerifyUser />
                      </span>
                      {userDetails.isKYCVerified ? (
                        <small className="mx-1 text-xs ">Verified User</small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="nav-menu block mobile-menu lg:hidden  ">
              <label
                htmlFor="hamburger"
                onClick={() => {
                  setShowMenu(false);
                  setshowMobileNotifications();
                }}
                className="hide-menu-icon m-5"
              >
                <i className="pi pi-times text-xl font-bold"></i>
              </label>
              {showMobileNotifications ? (
                <MobileNotification
                  notifications={notifications}
                  markNotificationAsRead={markNotificationAsRead}
                ></MobileNotification>
              ) : (
                <>
                  <NavLink
                    to={"/my-profile"}
                    onClick={() => {
                      setShowMenu(false);
                    }}
                    className={({ isActive }) =>
                      isActive ? "active-menu nav-link" : "nav-link"
                    }
                  >
                    <div className="flex align-items-center ">
                      <img src={Profile} alt="" style={{ width: "38px" }} />
                      <div className="flex flex-column align-items-start  justify-content-start mx-3">
                        <Menu
                          model={Myprofile}
                          popup
                          ref={menuRight}
                          id="popup_menu_right"
                        />
                        <h3 className="my-0 text-xs ">My Profile</h3>

                        <div className="flex align-items-center">
                          <span>
                            <VerifyUser />
                          </span>
                          <small className="mx-1 text-xs ">Verified User</small>
                        </div>
                      </div>
                    </div>
                  </NavLink>

                  <div className="mx-3 border-bottom-1 border-surface-600 padding-top mr-6 ">
                    <Accordion activeIndex={0}>
                      <AccordionTab header="Trade">
                        <div className="flex flex-column">
                          <ul className="my-0">
                            <li onClick={() => setShowMenu(false)}>
                              <span
                                className=""
                                onClick={() => navigate("/dashboard")}
                              >
                                Private Wallet
                              </span>
                            </li>
                            <li
                              className="py-3"
                              onClick={() => setShowMenu(false)}
                            >
                              Existing P2P
                            </li>
                          </ul>
                        </div>
                      </AccordionTab>
                    </Accordion>
                  </div>

                  <div className="active-color w-full-mobile ">
                    <NavLink
                      onClick={() => setShowMenu(false)}
                      className={({ isActive }) =>
                        isActive ? " active-menu nav-link" : "nav-link"
                      }
                      to="/transactions"
                    >
                      Transaction History
                    </NavLink>
                  </div>
                  <NavLink
                    onClick={() => setshowMobileNotifications(true)}
                    className={({ isActive }) =>
                      isActive ? "active-menu nav-link" : "nav-link"
                    }
                  >
                    Notification
                  </NavLink>
                  <NavLink
                    onClick={() => setShowMenu(false)}
                    className={({ isActive }) =>
                      isActive ? "active-menu nav-link" : "nav-link"
                    }
                  >
                    <span onClick={() => Logout()}> Logout</span>
                  </NavLink>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {[
              "/",
              "/about-us",
              "/contact-us",
              "/sign-in",
              "/register",
              "/privacy-policy",
              "/terms&Conditions",
            ].includes(location.pathname) ? (
              <div className="nav-menu  mobile-menu">
                <label
                  htmlFor="hamburger"
                  onClick={() => setShowMenu(false)}
                  className="hide-menu-icon m-5"
                >
                  <i className="pi pi-times text-xl font-bold"></i>
                </label>
                <NavLink
                  onClick={() => setShowMenu(false)}
                  className={({ isActive }) =>
                    isActive ? "active-menu nav-link" : "nav-link"
                  }
                  to="/"
                >
                  Home
                </NavLink>
                <NavLink
                  onClick={() => setShowMenu(false)}
                  className={({ isActive }) =>
                    isActive ? "active-menu nav-link" : "nav-link"
                  }
                  to="/about-us"
                >
                  About Us
                </NavLink>
                <NavLink
                  onClick={() => setShowMenu(false)}
                  className={({ isActive }) =>
                    isActive ? "active-menu nav-link" : "nav-link"
                  }
                  to="/contact-us"
                >
                  Support
                </NavLink>
                <div className="lg:flex lg:justify-content-between w-full-mobile ">
                  <NavLink
                    to="/sign-in"
                    className={({ isActive }) =>
                      isActive ? "active-menu nav-link" : "nav-link "
                    }
                  >
                    {["/sign-in"].includes(location.pathname) ? (
                      <ButtonComponent
                        label="Sign In"
                        className="border-none px-5"
                        onClick={() => setShowMenu(false)}
                      />
                    ) : (
                      <ButtonComponent
                        label="Sign In"
                        className="border-1 text-primary   bg-transparent px-5"
                        onClick={() => setShowMenu(false)}
                      />
                    )}
                  </NavLink>
                  <NavLink
                    to="/register"
                    className={({ isActive }) =>
                      isActive
                        ? "active-menu nav-link register-link"
                        : "nav-link register-link  "
                    }
                  >
                    {["/register"].includes(location.pathname) ? (
                      <ButtonComponent
                        label="Register"
                        className={"px-5 mx-3"}
                        onClick={() => setShowMenu(false)}
                      />
                    ) : (
                      <ButtonComponent
                        label="Register"
                        className={
                          "px-5 mx-3 border-1 text-primary bg-transparent"
                        }
                        onClick={() => setShowMenu(false)}
                      />
                    )}
                  </NavLink>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
