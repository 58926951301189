import moment from "moment";
import React from "react";

import { Tooltip } from "primereact/tooltip";

const MobileNotification = ({ notifications, markNotificationAsRead }) => {
  return (
    <>
      <div className="p-3 overflow-y-auto " style={{ height: "900px" }}>
        {notifications.length ? (
          notifications.map((notification) => {
            return (
              <div
                className={`${
                  notification.status === "Unread"
                    ? "text-white"
                    : "text-gray-600"
                } text-xs " `}
              >
                <ul className="p-2" style={{}}>
                  <Tooltip
                    target=".custom-tooltip-notification"
                    position="left"
                  >
                    mark as read
                  </Tooltip>
                  <li
                    className={`${
                      notification.status === "Unread"
                        ? "custom-tooltip-notification"
                        : ""
                    } mb-1 cursor-pointer shadow-3  hover:shadow-8 border-0 border-round-md  p-2 `}
                    onClick={() => markNotificationAsRead(notification._id)}
                  >
                    {notification.message}
                    <div className="flex justify-content-end">
                      {moment(notification.createdAt)
                        .startOf("seconds")
                        .fromNow()}
                    </div>
                  </li>
                </ul>{" "}
              </div>
            );
          })
        ) : (
          <span> No notifications </span>
        )}
      </div>
    </>
  );
};

export default MobileNotification;
