import React, { useEffect, useState } from "react";
import TableData from "../components/table/table";
import { MultiSelect } from "primereact/multiselect";
import Footer from "../layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../redux/actions/transactionsAction";
import * as moment from "moment";
import { CustomDropdown } from "../components/InputFields/CustomDropdown";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";

const Transactions = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let { allTransactions } = useSelector((state) => state.transactions);

  const [currentPage, setCurrentPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const searchKeys = ["fullName", "email"];
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    dispatch(
      getTransactions(
        setLoading,
        currentPage,
        rows,
        searchKeys,
        search,
        filterData
      )
    );
  }, [dispatch, currentPage, rows, search, filterData]);

  const representativeFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
      />
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <CustomDropdown
        options={["Pending", "Approved", "Processing"]}
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
      ></CustomDropdown>
    );
  };

  const networkFilterTemplate = (options) => {
    return (
      <CustomDropdown
        options={["tron", "ethereum", "polygon"]}
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
      ></CustomDropdown>
    );
  };

  const applyDateFilter = (e) => {
    for (let key of e.constraints) {
      let filterData = {};
      if (key.matchMode === "Date is before") {
        filterData = {
          ...filterData,
          endDate: moment(key.value).format("YYYY-MM-DD"),
        };
      } else if (key.matchMode === "Date is after") {
        filterData = {
          ...filterData,
          startDate: moment(key.value).format("YYYY-MM-DD"),
        };
      } else {
        filterData = {
          ...filterData,
          startDate: moment(key.value).format("YYYY-MM-DD"),
          endDate: moment(key.value).format("YYYY-MM-DD"),
        };
      }
      setFilterData((prev) => ({ ...prev, createdAt: filterData }));
    }
  };

  const onApplyFilters = (e) => {
    for (let key in e.filters) {
      if (key === "createdAt" && e.filters["createdAt"].constraints[0].value) {
        applyDateFilter(e.filters[key]);
      } else if (e.filters[key].constraints[0].value) {
        setFilterData((prev) => ({
          ...prev,
          [key]: e.filters[key].constraints[0].value,
        }));
      }
    }
  };

  const onFilterClear = (key) => {
    setFilterData((prev) => {
      delete prev[key];
      return {
        ...prev,
      };
    });
  };

  const srNumberTemplate = (col, row) => {
    return <span>{row.rowIndex + 1}</span>;
  };

  const createdAtTemplate = (col) => {
    return <span>{moment(col.createdAt).format("DD-MM-YY")}</span>;
  };

  const timeTemplate = (col) => {
    return <span>{moment(col.createdAt).format("HH:mm")}</span>;
  };

  const payInTemplate = (col) => {
    return (
      <span>
        {col.paidAmountCurrency} - {col.receivedAmountCurrency}
      </span>
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const transactionColumns = [
    {
      field: "SrNo",
      header: "Sr no",
      id: "",
      index: "",
      body: srNumberTemplate,
    },
    {
      header: "Date",
      field: "createdAt",
      id: "",
      index: "",
      filter: true,
      body: createdAtTemplate,
      filterElement: dateFilterTemplate,
      showFilterOperator: false,
      filterMatchModeOptions: ["Date is", "Date is before", "Date is after"],
      onFilterClear: () => onFilterClear("createdAt"),
    },

    {
      field: "createdAt",
      header: "Time",
      id: "",
      index: "",
      body: timeTemplate,
    },
    {
      field: "walletAddress",
      header: "Address",
      id: "",
      index: "",
    },
    {
      field: "PayIn",
      header: "Pay In",
      id: "",
      index: "",
      filter: false,
      body: payInTemplate,
    },
    {
      field: "network",
      header: "Network Type",
      id: "",
      index: "",
    },
    {
      field: "gasFees",
      header: "Gas Fees",
      id: "",
      index: "",
    },
    {
      field: "status",
      header: "Status",
      filter: true,
      filterElement: statusFilterTemplate,
      showFilterMatchModes: false,
      showAddButton: false,
      showFilterOperator: false,
      filterMatchModeOptions: ["Equals"],
      onFilterClear: () => onFilterClear("network"),
      id: "",
      index: "",
    },
  ];

  const [filters, setFilters] = useState({
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  return (
    <>
      <div className="container">
        <h3 className="text-30  my-3 font-semibold">Transaction History</h3>
        <div className="col-12 table-bg ">
          <TableData
            globalFilterFields={[
              "Date",
              "Network Type",
              "PayIn",
              "balance",
              "status",
            ]}
            value={allTransactions.transactions}
            columns={transactionColumns}
            data={allTransactions.transactions}
            onSearch={setSearch}
            searchKeyword={search}
            totalRecords={allTransactions.count}
            first={first}
            setFirst={setFirst}
            setCurrentPage={setCurrentPage}
            rows={rows}
            setRows={setRows}
            filters={filters}
            emptyMessage={"No transactions found."}
            onApplyFilters={onApplyFilters}
          ></TableData>
        </div>
      </div>
    </>
  );
};

export default Transactions;
