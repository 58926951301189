import React from "react";
import { Button } from "primereact/button";

export default function ButtonComponent({
  label,
  onChange,
  style,
  icon,
  iconPos,
  className,
  onClick,
  size,
  onPress,
  disable,
  fontSize,
  type,
}) {
  return (
    <div className={size}>
      <Button
        label={label}
        style={{
          padding: "12px 20px",
          fontSize: fontSize ? fontSize : "16px",
          ...style,
        }}
        className={`btn border-radius-10 font-semibold  ${className}`}
        icon={icon}
        iconPos={iconPos}
        onChange={onChange}
        onClick={onClick}
        onPress={onPress}
        disabled={disable}
        severity={type}
      />
    </div>
  );
}
