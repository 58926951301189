import { HashRouter, Routes, Route, useNavigate } from "react-router-dom";
import "./App.scss";
import "/node_modules/primeflex/primeflex.css";
import "primeicons/primeicons.css";
import Home from "./pages/Home";
import About from "./pages/About/Index.jsx";
import Index from "./layout/Index";
// import SignIn from "./pages/SignIn";
import ContactUS from "./pages/ContactUs/index";
import SignIn from "./pages/SignIn";
import ResetPassword from "./pages/resetPassword";
import SignUp from "./pages/SignUp";
import ToastContainer from "./components/toast";
import Loader from "./components/loader";
import { ProtectedRoute } from "./routes.js/protectedRoutes.js";
import { PrivateRoutes } from "./routes.js/index.jsx";
import TermsAndConditions from "./pages/TermsAndConditions.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import MyProfile from "./pages/Myprofile/Myprofile.jsx";
import EditProfile from "./pages/Myprofile/EditProfile.jsx";
import { useEffect, useState } from "react";
import { socket } from "./socket";
import { useDispatch } from "react-redux";
import { getNotifications } from "./redux/actions/notificationAction";
import EmailTemplate from "./pages/EmailTemplate.jsx";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CreateNewPassword from "./pages/resetPassword/CreateNewPassword";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket.connected]);

  useEffect(() => {
    socket.on("connect", () => dispatch(getNotifications(setLoading)));
    socket.on("disconnect", () => console.log("disconnected"));

    return () => {
      socket.off("connect");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket.connected]);

  const resetTimer = () => {
    const now = new Date();
    localStorage.setItem('lastActive', now);
  };

  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;
  window.ontouchstart = resetTimer;
  window.onclick = resetTimer;
  window.onkeypress = resetTimer;

  const checkIdleTime = () => {
    // Get the current time
    const now = new Date();
    // Get the last time the user was active
    const lastActive = localStorage.getItem('lastActive');
    // If the user has been inactive for more than 1 hour, log them out
    if (now - new Date(lastActive) > 60 * 60 * 1000) {
      sessionStorage.clear();
      navigate('/');
    }
    // Otherwise, set the last active time to the current time
    else {
      resetTimer();
    }
  }

  // Set the function to run every minute
  setInterval(checkIdleTime, 60000);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="App">
        <Loader />
        <ToastContainer />
        <GoogleOAuthProvider clientId="467408688141-j23qulpkuo2sv11d0su5842j899sdmtk.apps.googleusercontent.com">

          <Index socket={socket}>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/about-us" exact element={<About />} />
              <Route path="/sign-in" element={<SignIn />}></Route>
              <Route
                path="/reset-password"
                element={<ResetPassword />}
              ></Route>
              <Route path="/contact-us" element={<ContactUS />}></Route>
              <Route path="/register" element={<SignUp />}></Route>
              <Route
                path="/create-password"
                name="CreateNewPassword"
                element={<CreateNewPassword />}
              ></Route>
              <Route
                path="/terms&conditions"
                element={<TermsAndConditions />}
              ></Route>
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicy />}
              ></Route>
              <Route path="/my-profile" element={<MyProfile />}></Route>
              {/* <Route path="/edit-profile" element={<EditProfile />}></Route> */}
              <Route
                path="/email-template"
                element={<EmailTemplate />}
              ></Route>
              {/* <Route
                path="/kyc-verification-steps"
                element={<NotVerifyKYC />}
              ></Route>
              <Route
                path="/kyc-your-details"
                element={<KYCYourDetails />}
              ></Route>
              <Route
                path="/kyc-identity-document"
                element={<IdentityDocument />}
              ></Route>
              <Route
                path="/kyc-UploadDocument"
                element={<UploadDocument />}
              ></Route>
              <Route
                path="/fontcam-Document"
                element={<IdentityFontCamDocument />}
              ></Route>
              <Route
                path="/upload-fontcam-document"
                element={<UploadFrontCamPic />}
              ></Route> */}
              <Route element={<ProtectedRoute />}>
                {PrivateRoutes.map((route, key) => (
                  <Route
                    key={key}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Route>
            </Routes>
          </Index>

        </GoogleOAuthProvider>
      </div>
    </>
  );
}

export default App; 
