import React, { useState, useEffect } from "react";
import ButtonComponent from "../../components/Buttons/button";
import icons from "../../utils/icons";
import BlurEffect from "../../components/BlurEffect";
import CADicon from "../../assets/icons/CAD.png";
import USDTicon from "../../assets/icons/USDT.png";
import Register from "../../assets/images/register.png";
import VerifyKYC from "../../assets/images/yourkyc.png";
import Exchange from "../../assets/images/exchange.png";
import Footer from "../../layout/Footer";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../components/InputFields/CustomCheckbox";
import { CustomDropdown } from "../../components/InputFields/CustomDropdown";
import { CustomInput } from "../../components/InputFields/CustomInput";
import { isAuthenticated } from "../../services/authService";
import constants from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getNetworkFee } from "../../redux/actions/transactionsAction";
import { getGasFeesCurrencyList } from "../../redux/actions/gasFeesAction";

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { gasFees } = useSelector((state) => state.gasFees);

  const { PolygonIcon, EthereumIcon, TronIcon } = icons();
  const cryptoCurrencies = constants.cryptoCurrencies;

  useEffect(() => {
    dispatch(getGasFeesCurrencyList());
  }, []);

  const [transactionForm, setTransactionFormData] = useState({
    amountPaid: "",
    paidAmountCurrency: "USDT",
    amountReceived: "",
    receivedAmountCurrency: "CAD",
  });

  useEffect(() => {
    if (transactionForm.amountPaid) {
      const data = {
        payCurrency: transactionForm.paidAmountCurrency,
        receiveCurrency: transactionForm.receivedAmountCurrency,
        tradeType:
          transactionForm.paidAmountCurrency === "CAD" &&
          transactionForm.receivedAmountCurrency !== "USD"
            ? "BUY"
            : "SELL",
        amount: transactionForm.amountPaid,
      };
      getGasFee(data);
    }
  }, [transactionForm.amountPaid]);

  const getGasFee = async (data) => {
    const currencyPair = `${data.payCurrency}-${data.receiveCurrency}`;
    gasFees.map((fee) => {
      const feeCurrencyPair = `${fee.firstCurrency}-${fee.secondCurrency}`;
      if (currencyPair === feeCurrencyPair) {
        setTransactionFormData((prev) => ({
          ...prev,
          // gasFees: (
          //   (data.amount * (fee.fees ? fee.fees : fee.transactionFee)) /
          //   100
          // ).toFixed(2),
          amountReceived: (
            data.amount * fee.exchangePricePerUnit -
            (fee.fees ? fee.fees : fee.transactionFee)
          ).toFixed(2),
          // (
          //   (data.amount * fee.fees ? fee.fees : fee.transactionFee) / 100
          // ).toFixed(2),
        }));
      }
    });
  };

  const handleChange = async ({ name, value, label }) => {
    if (name === "amountPaid" && !value) transactionForm.amountPaid = "";
    if (name === "paidAmountCurrency" && value !== "CAD")
      transactionForm.receivedAmountCurrency = "CAD";
    if (name === "paidAmountCurrency" && value === "CAD")
      transactionForm.receivedAmountCurrency = "USDT";
    setTransactionFormData({ ...transactionForm, [name]: value });
  };

  const onReverse = () => {
    setTransactionFormData((prev) => {
      return {
        ...transactionForm,
        amountPaid: prev.amountReceived,
        paidAmountCurrency: prev.receivedAmountCurrency,
        amountReceived: prev.amountPaid,
        receivedAmountCurrency: prev.paidAmountCurrency,
      };
    });
  };

  const onClickSwap = () => {
    return isAuthenticated()
      ? navigate("/dashboard", { state: transactionForm })
      : navigate("/sign-in");
  };

  return (
    <>
      <div className="container bg-hero relative flex align-items-center justify-content-center">
        <div className=" text-white text-center pb-8 lg:mt-0 mt-8">
          <p className="text-40 font-semibold lg:mb-4 mb-2 ">
            <span className="text-primary">USDT</span>,{" "}
            <span className="text-primary">USDC</span>,{" "}
            <span className="text-green-500">USD</span> &{" "}
            <span className="text-primary">CAD</span> <br />
            Exchange Instantly with Tinbox
          </p>
          <p className="lg:text-lg lg:mb-5 mt-0">
            Exchange your currency securely with Tinbox
          </p>
          <ButtonComponent
            className={"z-1"}
            label="Get Started"
            onClick={() =>
              isAuthenticated() ? navigate("/dashboard") : navigate("/sign-in")
            }
          />
        </div>
        <BlurEffect left="-3%" top="5%" />
        {/* <BlurEffect right="-5%" top="40%" />s */}
      </div>

      <div className="container text-center relative pb-7 ">
        <h2 className="text-40 mb-2 font-semibold">
          Start Exchange In 3 Easy Steps
        </h2>
        <p className="text-sm lg:font-medium pb-3 ">Request, Send, Receive</p>
        <div className="flex flex-wrap my-6  gap-4 lg:gap-7  justify-content-between w-10 mx-auto flexbasis">
          <div className="col-12 md:col bg-secondary p-4 border-round-2xl flex flex-column justify-content-center align-items-center">
            <div className="home-icon-height ">
              <img src={Register} alt="" />
            </div>
            <h3 className="text-22 font-bold text-primary">Register</h3>
            <p>Register and trade with FINTRAC registered MSB</p>
          </div>
          <div className="col-12 md:col bg-secondary p-4 border-round-2xl flex flex-column  align-items-center">
            <div className="home-icon-height">
              <img src={VerifyKYC} style={{ width: "90px" }} alt="" />
            </div>
            <h3 className="text-22 font-bold text-primary">Non-intrusive</h3>
            <p>
              Hassle free and automated KYC process with 90% pass rate for
              seamless on-boarding
            </p>
          </div>
          <div className="col-12 md:col bg-secondary p-4 border-round-2xl  flex flex-column justify-content-center align-items-center">
            <div className="home-icon-height">
              <img src={Exchange} alt="" />
            </div>
            <h3 className="text-22 font-bold text-primary">Exchange</h3>
            <p>
              Buy & Sell <br />
              Stablecoins in seconds
            </p>
          </div>
        </div>
        <BlurEffect left="-5%" bottom="-20%" />
      </div>

      <div className="bg-secondary lg:pt-6 ">
        <div className="container lg:px-3 lg:py-8">
          <div className="flex flex-wrap">
            <div className="col-12 md:col-6">
              <div className="md:mr-7 lg:pb-6 pb-3 mb-3">
                <h2 className="text-40 font-semibold mb-0">
                  Document-Free KYC Verification
                </h2>
                <p className="mb-4 text-sm md:text-base ">
                  Get verified within seconds through our industry leading
                  partner, enjoying a user-friendly interface and an intuitive
                  verification process. Easily submit your identity documents on
                  the platform of your preference-whether it's Desktop, Ios or
                  Android and seamlessly complete the entire ID verification
                  process online.
                </p>
                <ButtonComponent
                  label="Get Started"
                  className={"z-1"}
                  onClick={() =>
                    isAuthenticated()
                      ? navigate("/dashboard")
                      : navigate("/sign-in")
                  }
                />
              </div>
            </div>
            <div className="col-12 lg:col-6    flex align-items-center justify-content-center md:col-6 bg-black-50 mb-6 ">
              <div className="text-center px-3">
                <div className="">
                  <img src={VerifyKYC} alt="" />
                </div>
                <p className="text-lg font-bold">Hassle free KYC</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container lg:pt-5 relative">
        <div className="flex flex-wrap flex-column-reverse md:flex-row my-5 lg:my-8 ">
          <div className="col-12  p-0 md:col-6 md:pr-6 div-width">
            <div className="p-4 lg:mr-7 lg:p-5 lg:py-6 bg-secondary lg:border-round-2xl">
              <p className="text-sl font-semibold mt-0">
                Buy & Sell Stablecoins in Canada
              </p>
              <div className="bg-secondary border-radius-10">
                <div className="flex  justify-content-between align-items-center">
                  <div className="">
                    <p className="text-xs mt-0 pt-3 pl-3">You Pay</p>
                    <div className="mobile-inputsize m-0 p-0">
                      <CustomInput
                        extraClass={"border-none"}
                        placeholder="0"
                        name="amountPaid"
                        type={"number"}
                        value={transactionForm.amountPaid}
                        onChange={handleChange}
                      ></CustomInput>
                    </div>
                  </div>
                  <div className="relative pr-2 lg:p-3">
                    <div className="bg-gray-200 flex align-items-center  p-2  border-round-3xl gap-3">
                      <p className="text-color m-0 Customdrop">
                        <CustomDropdown
                          // placeholder={"CAD"}
                          className="bg-transparent"
                          options={cryptoCurrencies}
                          name="paidAmountCurrency"
                          value={transactionForm.paidAmountCurrency}
                          onChange={handleChange}
                          optionLabel={""}
                        ></CustomDropdown>
                      </p>
                      {/* <i className="coin-dropdown-arrow mr-2" /> */}
                    </div>
                  </div>
                  {/* <div className="relative">
                    <div className="bg-surface-a flex align-items-center  p-2 border-round-3xl gap-3 bg-white">
                      <img
                        src={CADicon}
                        style={{ width: "20px", height: "20px" }}
                        alt="CAD"
                      />
                      <p className="text-secondary m-0">CAD</p>
                      <i className="coin-dropdown-arrow mr-2" />
                    </div>
                  </div> */}
                </div>
                <div className="flex relative  ">
                  <div className="col-7 p-0"></div>
                  <div className="col-5 p-0 text-center z-1">
                    <i
                      className="pi pi-sort-alt p-2 border-circle bg-white text-secondary"
                      onClick={onReverse}
                    />
                  </div>
                  <hr className="absolute w-full" style={{ top: "25%" }} />
                </div>
                <div className="flex  justify-content-between align-items-center">
                  <div className="">
                    <p className="text-xs pt-3 pl-3 mt-0 ">You Get</p>
                    <div className="mobile-inputsize m-0 p-0">
                      <CustomInput
                        extraClass={"border-none"}
                        disabled
                        placeholder="0"
                        name="amountReceived"
                        value={transactionForm.amountReceived}
                        onChange={handleChange}
                      ></CustomInput>
                    </div>
                  </div>
                  {/* <div className="relative">
                    <div className="bg-surface-a flex align-items-center  p-2 border-round-3xl gap-3 bg-white">
                      <img
                        src={USDTicon}
                        style={{ width: "20px", height: "20px" }}
                        alt="CAD"
                      />
                      <p className="text-secondary m-0">USDT</p>
                      <i className="coin-dropdown-arrow mr-2" />
                    </div>
                  </div> */}
                  <div className="relative pr-2 lg:p-3">
                    <div className="bg-gray-200 flex align-items-center  p-2  border-round-3xl">
                      <p className="text-color m-0 Customdrop">
                        <CustomDropdown
                          // placeholder={"CAD"}
                          className="bg-transparent"
                          options={
                            transactionForm.paidAmountCurrency !== "CAD"
                              ? ["CAD"]
                              : cryptoCurrencies.filter(
                                  (item) =>
                                    item !== transactionForm.paidAmountCurrency
                                )
                          }
                          name="receivedAmountCurrency"
                          value={transactionForm.receivedAmountCurrency}
                          onChange={handleChange}
                          optionLabel={""}
                        ></CustomDropdown>
                      </p>
                      {/* <i className="coin-dropdown-arrow mr-2" /> */}
                    </div>
                  </div>
                </div>
              </div>
              {transactionForm.amountPaid ? (
                <p className="text-xs pl-2">
                  Estimated price: {transactionForm.amountPaid}{" "}
                  {transactionForm.paidAmountCurrency} ≈{" "}
                  {transactionForm.amountReceived}{" "}
                  {transactionForm.receivedAmountCurrency}
                </p>
              ) : (
                <br />
              )}
              <ButtonComponent
                label="Swap"
                className="w-full z-1"
                onClick={onClickSwap}
              />
            </div>
          </div>
          <div className="  col-12 md:col-6 mb-6 lg:mb-0  ">
            <h2 className="text-40 font-semibold sm:my-0 lg:mt-6  ">
              Simplified Currency Exchange Process
            </h2>
            <p className="text-sm md:text-base  font-medium">
              Exchange smoothly between currencies with our quick and intuitive
              trading process. This effortless system encompasses the submission
              of a trade request form, immediate updates at each transaction
              stage, and rapid delivery of funds. Leveraging the simplicity and
              speed of Interac E-Transfer for fiat transactions, we assure a
              smooth and efficient trading experience. With our platform, ease,
              speed, and reliability are truly at your fingertips.
            </p>
            <ButtonComponent
              label="Get Started"
              className={"z-1"}
              onClick={() =>
                isAuthenticated()
                  ? navigate("/dashboard")
                  : navigate("/sign-in")
              }
            />
          </div>
        </div>

        <div className="flex my-5 lg:my-8 ipad-breakpoint">
          <div className="col-12 md:col-6 lg:my-6">
            <h2 className="text-40 font-semibold mb-0">Networks We Support</h2>
            <p className="font-medium">
              Our platform proudly supports ERC20, TRC20, and POLYGON networks,
              demonstrating our commitment to stay ahead of the curve in the
              crypto landscape. We are in a constant process of expansion,
              intending to integrate more networks for our user's convenience.
              If your preferred network is currently not on our list, feel free
              to reach out to our dedicated support team. We are more than happy
              to assist and explore the possibilities of facilitating exchanges
              on other networks to serve you better.
            </p>
          </div>
          <div className="col-12 flex network-card-container justify-content-around align-items-center md:col-6">
            <div className="border-circle network-support-card m-2">
              <PolygonIcon />
              <p className="font-bold text-xs">Polygon</p>
            </div>
            <div className="border-circle network-support-card ">
              <EthereumIcon />
              <p className="font-bold text-xs">Ethereum</p>
            </div>

            <div className="flex  ">
              <div className="border-circle network-support-card">
                <TronIcon />
                <p className="font-bold text-xs">Tron</p>
              </div>
            </div>
          </div>
        </div>
        <BlurEffect right="-5%" top="40%" />
      </div>
      <div className="container-fluid bg-secondary">
        <Footer />
      </div>
    </>
  );
}
